import { deletePrompt } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import LinkLookup from 'components/LinkLookup';
import { promptKindMap, promptSourceMap } from 'constants/entities';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'types';
import EditPromptModal from './EditPromptModal';

type ComponentProps = {
  prompt: Prompt;
  selected: boolean;
  setSelectedPrompts: React.Dispatch<React.SetStateAction<string[]>>;
};

const PromptListRow: React.FC<ComponentProps> = ({ prompt, selected, setSelectedPrompts }) => {
  const dispatch = useDispatch();

  const [isEditingPrompt, setIsEditingPrompt] = useState(false);
  const [isDeletingPrompt, setIsDeletingPrompt] = useState(false);

  // @ts-ignore
  const { uuid, name, kind, deployment, source } = prompt;

  const toggleEditModal = (): void => setIsEditingPrompt(!isEditingPrompt);
  const toggleDeleteModal = (): void => setIsDeletingPrompt(!isDeletingPrompt);

  const handleDeletePrompt = (): void => {
    dispatch(deletePrompt({ uuid: prompt.uuid }));
    toggleDeleteModal();
  };

  const handleTagGroupSelect = (checked: boolean): void => {
    if (checked) {
      setSelectedPrompts((prev) => [...prev, uuid]);
    } else {
      setSelectedPrompts((prev) => prev.filter((id) => id !== uuid));
    }
  };

  const handleRowCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    handleTagGroupSelect(e.target.checked);
  };

  return (
    <>
      <LinkLookup
        routeName="prompt-detail"
        routeParams={{
          promptId: uuid,
        }}
        className="border-t border-gray-200 hover:bg-gray-100 table-row h-10"
        as="tr"
      >
        <td className="table-wrapper__new-td flex w-full">
          <div className="w-full pt-2.5 px-2 min-h-8 flex justify-end">
            <input
              id={`checkbox-user-${uuid}`}
              /* @ts-ignore */
              data-testid={`checkbox-${name}`}
              type="checkbox"
              className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
              onClick={(e): void => e.stopPropagation()}
              onChange={(e): void => {
                handleRowCheckboxChange(e);
              }}
              checked={selected}
            />
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
            <div className="flex overflow-hidden">
              <div className="font-bold whitespace-no-wrap truncate">{name}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
            <div className="flex overflow-hidden">
              <div>{promptKindMap[kind]}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
            <div className="flex overflow-hidden">
              <div className="whitespace-no-wrap truncate">{deployment}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
            <div className="flex overflow-hidden">
              <div>{promptSourceMap[source] ?? '–'}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex flex-row items-start gap-1 py-2.5 pr-4 text-body">
            <div className="flex overflow-hidden">
              <div>-</div>
            </div>
          </div>
        </td>
      </LinkLookup>
      {isEditingPrompt && (
        <EditPromptModal
          isModalOpen={isEditingPrompt}
          toggleModalOpen={toggleEditModal}
          prompt={prompt}
        />
      )}
      {isDeletingPrompt && (
        <ConfirmModal
          data-testid="delete-modal"
          text="Are you sure you want to delete this promt?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeletePrompt}
          toggleShowModal={toggleDeleteModal}
        />
      )}
    </>
  );
};

export default PromptListRow;
