import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  deletePromptSuccess,
  fetchEntitiesRequest,
  fetchEntitiesSuccess,
  fetchPromptsRequest,
  fetchPromptsSuccess,
  fetchSinglePromptRequest,
  fetchSinglePromptSuccess,
  mergeEntitiesSuccess,
  upsertPromptSuccess,
} from 'actions';

import { API, Entity, ErrorObject, Prompt, UUID } from 'types';

type EntityState = {
  loading: string[];
  error: ErrorObject | null;
  entities: Entity[];
  prompts: Prompt[];
  activePrompt: Prompt | null;
  activeEntity: Entity | null;
  entitiesCount: number;
  promptsCount: number;
};

const defaultState: EntityState = {
  loading: [],
  error: null,
  entities: [],
  prompts: [],
  activePrompt: null,
  activeEntity: null,
  entitiesCount: 0,
  promptsCount: 0,
};
type EntityReducer<P = void> = (state: EntityState, payload: PayloadAction<P>) => EntityState;

const handlefetchSinglePromptSuccess: EntityReducer<Prompt> = (state, { payload }) => ({
  ...state,
  activePrompt: payload,
  loading: state.loading.filter((s) => s !== fetchSinglePromptRequest.toString()),
});

const handleFetchSinglePromptRequest: EntityReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchSinglePromptRequest.toString()],
});

const handleUpsertPromptSuccess: EntityReducer<Prompt> = (state, { payload }) => {
  const prompt = state.prompts.find((p) => p.uuid === payload.uuid);

  if (prompt) {
    return {
      ...state,
      prompts: state.prompts.map((p) => (p.uuid === payload.uuid ? payload : p)),
      activePrompt: payload,
    };
  }
  return {
    ...state,
    prompts: [...state.prompts, payload],
    activePrompt: payload,
  };
};

const handleDeletePromptSuccess: EntityReducer<{ uuid: UUID }> = (state, { payload }) => {
  const newPrompts = state.prompts.filter((p) => p.uuid !== payload.uuid);
  return {
    ...state,
    prompts: newPrompts,
  };
};

const handleMergeEntitiesSuccess: EntityReducer<UUID> = (state, { payload }) => {
  const entity = state.entities.find((e) => e.uuid === payload);

  if (entity) {
    return {
      ...state,
      entities: state.entities.filter((e) => e.uuid !== payload),
    };
  }
  return state;
};

const handleFetchEntitiesRequest: EntityReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchEntitiesRequest.toString()],
});

const handleFetchEntitiesSuccess: EntityReducer<API.WrappedAPIResponse<Entity>> = (
  state,
  { payload }
) => ({
  ...state,
  entities: payload.records,
  entitiesCount: payload.count,
  loading: state.loading.filter((s) => s !== fetchEntitiesRequest.toString()),
});

const handleFetchPromptsRequest: EntityReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchPromptsRequest.toString()],
});

const handleFetchPromptsSuccess: EntityReducer<API.WrappedAPIResponse<Prompt>> = (
  state,
  { payload }
) => ({
  ...state,
  prompts: payload.records,
  promptsCount: payload.count,
  loading: state.loading.filter((s) => s !== fetchPromptsRequest.toString()),
});

const handlers = {
  [fetchSinglePromptSuccess.toString()]: handlefetchSinglePromptSuccess,
  [fetchSinglePromptRequest.toString()]: handleFetchSinglePromptRequest,
  [upsertPromptSuccess.toString()]: handleUpsertPromptSuccess,
  [deletePromptSuccess.toString()]: handleDeletePromptSuccess,
  [mergeEntitiesSuccess.toString()]: handleMergeEntitiesSuccess,
  [fetchEntitiesRequest.toString()]: handleFetchEntitiesRequest,
  [fetchEntitiesSuccess.toString()]: handleFetchEntitiesSuccess,
  [fetchPromptsRequest.toString()]: handleFetchPromptsRequest,
  [fetchPromptsSuccess.toString()]: handleFetchPromptsSuccess,
};

const entitiesReducer = createReducer(defaultState, handlers);

export default entitiesReducer;
