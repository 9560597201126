/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { setStateFilter } from 'actions';
import { groupCategories } from 'components/CategoriesTable';
import CreateCategoryModal from 'components/Category/CreateCategoryModal';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import SelectRedesign from 'components/Select/SelectRedesign';
import { INDENTATION_ICON, NEW_EDIT_ICON } from 'constants/commonIcons';
import pluralize from 'pluralize';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getModel,
  getModelCategories,
  getStateFilter,
  getUpsertModelLoading,
} from 'selectors/models';
import { useSelector } from 'store';
import EditModelModal from './EditModelModal';
import ModelPromotionModal from './ModelPromotionModal';

type RouterParams = { ruleId: string; modelId: string };

const ModelHeader: React.FC = () => {
  const { modelId } = useParams<RouterParams>();
  const dispatch = useDispatch();

  const model = useSelector(getModel(modelId));
  const loading = useSelector(getUpsertModelLoading);
  const categories = useSelector((state) => getModelCategories(state, modelId));
  const stateFilter = useSelector(getStateFilter);
  const groupedCategories = groupCategories(categories);

  const [isFullDescription, setIsFullDescription] = useState(false);
  const [clampedDescription, setClampedDescription] = useState(false);

  const [isEditingModel, setIsEditingModel] = useState(false);
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);

  const descriptionRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        const newC = node.scrollHeight > node.clientHeight + 2;
        setClampedDescription(newC);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model, isFullDescription]
  );

  let categoriesLenght = Object.values(groupedCategories).length;
  if (stateFilter) {
    if (stateFilter === 'staging') {
      categoriesLenght = Object.values(groupedCategories).reduce((acc, val) => {
        // @ts-ignore
        if (val.categories.some((c) => c.tenant_name === 'modeler')) {
          return acc + 1;
        }
        return acc;
      }, 0);
    }
    if (stateFilter === 'production') {
      categoriesLenght = Object.values(groupedCategories).reduce((acc, val) => {
        // @ts-ignore
        if (val.categories.some((c) => c.tenant_name === 'human')) {
          return acc + 1;
        }
        return acc;
      }, 0);
    }
  }

  const handleClinkOnEdit = (): void => {
    setIsEditingModel(true);
  };

  const toggleCreateCategoryModal = (): void => {
    setIsCreatingCategory(!isCreatingCategory);
  };

  const toggleEditModal = (): void => setIsEditingModel(false);

  const togglePromoteModal = (): void => {
    setShowPromoteModal((b) => !b);
  };

  const promoteAllButton = (
    <Permissions action="rules.create">
      <span className="rounded-md">
        <button
          onClick={(e): void => {
            e.stopPropagation();
            togglePromoteModal();
          }}
          type="button"
          data-testid="create-rule-form"
          className="button button--primary h-8"
          disabled={
            /* @ts-ignore */
            !categories.some((c) => c.tenant_name === 'modeler') ||
            /* @ts-ignore */
            !categories.some((c) => c.tenant_name === 'human')
          }
        >
          View Change Summary
        </button>
      </span>
    </Permissions>
  );

  const createCategoryButton = (
    <Permissions action="rules.create">
      <span className="rounded-md">
        <button
          onClick={(e): void => {
            e.stopPropagation();
            setIsCreatingCategory(true);
          }}
          type="button"
          data-testid="create-rule-form"
          className="button button--secondary flex flex-row font-bold h-8 w-32 justify-center"
        >
          Add Model
        </button>
      </span>
    </Permissions>
  );

  const renderActions = (
    <div className="flex flex-row gap-2">
      {createCategoryButton}
      {promoteAllButton}
    </div>
  );

  const stateFilterOptions = [
    { label: 'Staging', value: 'staging' },
    { label: 'Production', value: 'Production' },
  ];

  if (!model) return null;

  return (
    <>
      <header>
        <div className="flex flex-col gap-3 px-6">
          <div className="flex flex-row items-center justify-between">
            <LinkLookup
              routeName="global-models-list"
              className="underline text-small focus:outline-none"
            >
              All Use Cases
            </LinkLookup>
            {renderActions}
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-row items-baseline gap-2">
              <span className="text-heading-2 break-words">{model.name}</span>
              <button
                type="button"
                className="flex-none w-5 self-baseline focus:outline-none"
                onClick={handleClinkOnEdit}
              >
                {NEW_EDIT_ICON('text-litlingo-primary-120')}
              </button>
              {loading && <LoadingIndicator />}
            </div>

            <div className="flex flex-row justify-between items-stretch">
              <div className="flex flex-col h-full justify-start gap-2 w-3/5">
                {model && model.description && (
                  <div
                    className={`${
                      isFullDescription ? 'break-words' : 'flex flex-row break-all'
                    } text-body w-80ch`}
                  >
                    <span
                      ref={descriptionRef}
                      className={`description ${isFullDescription ? '' : 'clamped-description'}`}
                    >
                      {model.description}
                    </span>
                    {(clampedDescription || isFullDescription) && (
                      <button
                        className={`text-body focus:outline-none ${
                          isFullDescription ? 'ml-2' : ''
                        }`}
                        type="button"
                        onClick={(): void => setIsFullDescription((v) => !v)}
                      >
                        <span className="underline text-litlingo-primary whitespace-no-wrap">
                          {isFullDescription ? 'Less' : 'More'}
                        </span>
                      </button>
                    )}
                  </div>
                )}
                {model.categories && (
                  <div className="flex flex-row gap-2 pl-6 text-small">
                    {INDENTATION_ICON}
                    <span className="flex gap-1">
                      Priority {model.priority}, Has {Object.keys(groupedCategories).length}{' '}
                      {pluralize('Model', Object.keys(groupedCategories).length)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-end justify-between">
            <div className="flex flex-row gap-4 items-end">
              <div className="w-45">
                <SelectRedesign
                  onChange={(v): void => {
                    dispatch(setStateFilter(v));
                  }}
                  options={stateFilterOptions}
                  value={stateFilter}
                />
              </div>
              <button
                type="button"
                className={`text-litlingo-gray-4 ${
                  stateFilter ? 'hover:underline' : 'cursor-default'
                } focus:outline-none`}
                onClick={(): void => {
                  dispatch(setStateFilter(''));
                }}
                disabled={!stateFilter}
              >
                Clear All
              </button>
            </div>
            <span>
              <b>{`${categoriesLenght > 0 ? '1' : '0'}-${categoriesLenght}`}</b>
              {` of ${Object.keys(groupedCategories).length} ${pluralize(
                'Model',
                Object.keys(groupedCategories).length
              )}`}
            </span>
          </div>
        </div>
      </header>

      {isEditingModel && (
        <EditModelModal
          isModalOpen={isEditingModel}
          toggleModalOpen={toggleEditModal}
          modelToEdit={model}
        />
      )}
      {isCreatingCategory && (
        <CreateCategoryModal
          modelId={model.uuid}
          toggleModal={toggleCreateCategoryModal}
          redirect="global-rule-group-manager"
        />
      )}
      {showPromoteModal && <ModelPromotionModal setShowPromoteModal={setShowPromoteModal} />}
    </>
  );
};

export default ModelHeader;
