import { clearTree, requestTreeFiltersToogle } from 'actions';
import { allowedReadOnlyDimensions, metricsLinksMap } from 'constants/dashboard';
import { DASH_ICON } from 'constants/dashboardIcons';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetError from 'decorators/widget/WidgetError';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DashboardMetric, DefaultWidgetComponentProps, MetricsDataV2 } from 'types';
import logEvent from 'utils/analytics';
import { buildPeriodTrendDataFromMetrics } from 'utils/dashboard';
import { getValuesFromDimensions, setNewValue, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { NUMBER_OF_PERIODS } from 'utils/timeRanges';
import { useHistory } from 'utils/urls';

export const PrioritiesTrendRedesignWidget: React.FC<
  DefaultWidgetComponentProps & { widgetData: MetricsDataV2 }
> = (props) => {
  const { widgetData, queryData, widgetId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildPeriodTrendDataFromMetrics(widgetData, true);

  if ('error' in processedData && processedData.error != null) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('data' in processedData && processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  const handleClick = (query: DashboardMetric, data?: { id: string; timestamp: string }): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);
    const resourceId = data?.id;

    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const dimensions: Record<string, string | string[]> = {};
    const infoDimension = info.dimensions;
    if (infoDimension != null) {
      // Top level dimensions
      Object.entries(infoDimension).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        let infoParam;

        if (Array.isArray(query.group_by)) {
          infoParam = infoDimension[query.group_by[query.group_by.length - 1]];
        } else {
          infoParam = query.group_by && infoDimension[query.group_by];
        }

        // add the resource uuid whose dimension was used as group by
        if (query.group_by && infoParam === param && resourceId) {
          value = resourceId;
        }

        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });

      // Metric level dimensions
      if (query.dimensions != null) {
        query.dimensions.forEach((dimension) => {
          const allowed = allowedReadOnlyDimensions[query.metric];

          // if allowedDimension is not defined or the dimension is not included,
          // just ignore it
          if (allowed == null || !allowed.includes(dimension.name)) {
            return;
          }

          if (!dimensions[`${info.resource}__${infoDimension[dimension.name]}`]) {
            dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = dimension.value;
          }
        });
      }
    }

    // Metric level dimensions have more priority than top leve dimensions
    // multiple query.period by n for trailing avg

    const start = moment.utc(data?.timestamp).format(FULL_DATE_FORMAT);

    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const tree = getValuesFromDimensions(dimensions);

    const newTree = setNewValue(tree, 'date_range', `${start}<>${end}`, '', true);

    dispatch(requestTreeFiltersToogle({ value: true }));
    dispatch(clearTree());
    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__filters_search`]: transformToString(newTree),
        [`${info.resource}__has_events`]: ['true', 'false'],
        ...dimensions,
      },
    });
  };

  return (
    <div className="h-full w-full flex items-end">
      <div
        className="grid gap-1 w-full text-menu-item"
        style={{
          gridTemplateRows: 'auto 1fr',
          gridTemplateColumns: '0.1fr 1fr 0.3fr 0.3fr',
        }}
      >
        <span className="col-span-2">Focus Areas</span>
        <span>Count</span>
        <span>Trend</span>
        <div className="flex flex-col bg-white rounded overflow-hidden col-start-1 row-start-2 col-span-4">
          {'data' in processedData &&
            processedData.data.map((data, idx) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                className={`grid ${
                  idx !== 0 ? 'border-t border-litlingo-gray-3' : ''
                } hover:bg-litlingo-gray-0.5`}
                style={{
                  gridTemplateColumns: '0.1fr 1fr 0.3fr 0.3fr',
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={`${data.label}--${idx}`}
                // @ts-ignore
                onClick={(): void => handleClick(queryData.queries[0], data)}
                role="button"
                tabIndex={0}
              >
                <div className="flex flex-row justify-center items-center min-w-10 text-heading-3 bg-litlingo-secondary-80">
                  <svg width={4} height={4} viewBox="0 0 4 4" fill="black">
                    <circle cx={2} cy={2} r={2} />
                  </svg>
                </div>

                <div
                  className="flex-1 flex items-center px-2 py-2 text-body truncate cursor-pointer"
                  aria-hidden
                >
                  <div className="truncate">{data.label}</div>
                </div>

                <div className="flex items-center text-body cursor-pointer" aria-hidden>
                  {data.count}
                </div>
                <div className="flex text-body items-center pl-3 cursor-pointer" aria-hidden>
                  {!!data.nonZeroPeriodsCount && data.nonZeroPeriodsCount >= NUMBER_OF_PERIODS
                    ? data.icon
                    : DASH_ICON}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default withWidget({
  fetchAvgPeriod: true,
  bgClassColor: 'bg-transparent',
})(PrioritiesTrendRedesignWidget as React.FC<DefaultWidgetComponentProps>);
