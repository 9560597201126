/* eslint-disable react/destructuring-assignment */
import type { AnnotatedSuperNodeT, Annotation } from '@litlingo/react';
import { annotateCommunicationGroups, annotateEventSentences } from '@litlingo/react';
import { createSelector } from '@reduxjs/toolkit';
import {
  addEventLabelRequest,
  fetchAllEventDocCommRequest,
  fetchBulkEventSummariesRequest,
  fetchEventDocCommRequest,
  fetchEventLabelsRequest,
  fetchEventSummariesRequest,
  fetchEventSummaryRequest,
  fetchEventsRequest,
  removeEventLabelRequest,
} from 'actions';
import { keyActions } from 'constants/envelope';
import { platformNames } from 'constants/platform';
import { defaultReviewer } from 'constants/reviewSets';
import { GlobalState } from 'reducers';
import { getSurroundingContextRulesIds, getUser } from 'selectors/auth';
import { getPermissionsPolicy } from 'selectors/permissions';
import type {
  API,
  Action,
  Event,
  EventWithSummary,
  RenderedAction,
  Rule,
  Selector,
  UUID,
} from 'types';
import { IGNORE_TYPES, TERMINAL_ACTIONS } from 'utils/activityLogLookup';
import { isFieldUnprotected } from 'utils/permissions';
import {
  getCampaignsRulesForHistory,
  getHoverIdentifier,
  getSelectedCampaign,
  getSelectedModel,
  getSelectedRule,
  getSelectedSentence,
} from './envelopeReview';

export const getEventsList: Selector<EventWithSummary[]> = createSelector(
  [(state: GlobalState): GlobalState['events']['events'] => state.events.events],
  (events) => Object.values(events)
);

export const getEventsLoading: Selector<boolean> = (state) =>
  state.events.loading.includes(fetchEventsRequest.toString());

export const getEventDocCommLoading: Selector<boolean> = (state) =>
  state.events.loading.includes(fetchEventDocCommRequest.toString());

export const getAllEventDocCommLoading: Selector<boolean> = (state) =>
  state.events.loading.includes(fetchAllEventDocCommRequest.toString());

export const getEventSummaryLoading: Selector<boolean> = (state) =>
  state.events.loading.includes(fetchEventSummaryRequest.toString());

export const getEventSummariesLoading: Selector<boolean> = (state) =>
  state.events.loading.includes(fetchEventSummariesRequest.toString());

export const getEventBulkSummariesLoading: Selector<boolean, [UUID]> = (state, commUuid) =>
  state.events.loading.includes(`${fetchBulkEventSummariesRequest.toString()}/${commUuid}`);

export const getEventsLabelsLoading: Selector<boolean> = (state) =>
  state.events.loading.includes(fetchEventLabelsRequest.toString());

export const addEventLabelLoading: Selector<boolean> = (state) =>
  state.events.loading.includes(addEventLabelRequest.toString());

export const removeEventLabelLoading: Selector<boolean> = (state) =>
  state.events.loading.includes(removeEventLabelRequest.toString());

export const getSingleEvent: Selector<EventWithSummary, [UUID]> = (state, eventId) => {
  const { events } = state.events;

  return events[eventId];
};

export const getSingleEventActions: Selector<RenderedAction[], [UUID]> = (state, eventId) => {
  const renderedActions = state.events.events[eventId]?.renderedActions;
  const eventPlatform = state.events?.events[eventId]?.platform;
  if (!renderedActions) {
    return [];
  }

  const actions = renderedActions.filter(
    (action) =>
      !IGNORE_TYPES.includes(action.type) &&
      // For now we just skip show modal for slack
      (eventPlatform !== 'slack' || action.type !== 'show_modal')
  );

  return actions.sort((a, b) => {
    if (a.created_at == null || b.created_at == null) {
      return 0;
    }

    const dateA: Date = new Date(a.created_at);
    const dateB: Date = new Date(b.created_at);
    return dateB.getTime() - dateA.getTime();
  });
};

export const getModalActionForEvent: Selector<Action | null, [UUID]> = (state, eventId) => {
  const event = getSingleEvent(state, eventId);
  if (event.actions == null) {
    return null;
  }

  const modal = event.actions.find((a) => a.value === 'modal');

  return modal ?? null;
};

export const getEventSubject: Selector<string, [UUID]> = (state, eventId) => {
  let subject = ``;
  const rawActions = state.events.events[eventId]?.actions;
  if (!rawActions || !rawActions.length) return subject;
  rawActions.forEach((action) => {
    const { type } = action;
    if (type in TERMINAL_ACTIONS) {
      subject = TERMINAL_ACTIONS[type as keyof typeof TERMINAL_ACTIONS];
    }
  });

  return subject;
};

export const getSingleEventComments: Selector<Action[], [UUID]> = (state, eventId) => {
  let comments: Action[] = [];
  const rawActions = state.events.events[eventId]?.actions;
  if (!rawActions) {
    return comments;
  }

  comments = rawActions
    .filter((item) => item.type === 'comment')
    .sort((a, b) => {
      if (a.created_at == null || b.created_at == null) {
        return 0;
      }

      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB.getTime() - dateA.getTime();
    });

  return comments;
};

export const getPreviousSingleEvent =
  (eventId: UUID): Selector<EventWithSummary | null> =>
  ({ events: stateEvents }): EventWithSummary | null => {
    const { events, listIds } = stateEvents;
    const currentIdx = listIds.indexOf(eventId);

    if (currentIdx === -1 || currentIdx === 0) {
      return null;
    }

    return events[listIds[currentIdx - 1]];
  };

export const getNextSingleEvent =
  (eventId: UUID): Selector<EventWithSummary | null> =>
  ({ events: stateEvents }): EventWithSummary | null => {
    const { events, listIds } = stateEvents;
    const currentIdx = listIds.indexOf(eventId);

    if (currentIdx === -1 || currentIdx === listIds.length - 1) {
      return null;
    }

    return events[listIds[currentIdx + 1]];
  };

export const getCommunication: Selector<UUID[], [UUID]> = (state, id) =>
  state.events.communications[id];

export const getEvent: Selector<EventWithSummary, [UUID]> = (state, id) => state.events.events[id];

export const getRelatedEvents: Selector<Event[], [UUID]> = (state, eventId) => {
  const event = getEvent(state, eventId);
  if (!event) {
    return [];
  }

  const communication = getCommunication(state, event.communication_uuid);
  if (!communication) {
    return [];
  }

  return communication.filter((id) => id !== eventId).map((id) => state.events.events[id]);
};

export const getEventAnnotatedSentences =
  (eventId: UUID): Selector<ReturnType<typeof annotateEventSentences>['sentences'] | null> =>
  ({ events: stateEvents }): ReturnType<typeof annotateEventSentences>['sentences'] | null => {
    const event = stateEvents.events[eventId];
    if (event == null || event.annotations == null || event.summaries == null) {
      return null;
    }

    return annotateEventSentences(event.summaries, event.annotations).sentences;
  };

export const getEventWholeSetences =
  (eventId: UUID): Selector<ReturnType<typeof annotateEventSentences>['sentences'] | null> =>
  (state): ReturnType<typeof annotateEventSentences>['sentences'] | null => {
    const event = getSingleEvent(state, eventId);
    if (event == null || event.annotations == null || event.sentences == null) {
      return null;
    }

    return annotateEventSentences(event.sentences, event.annotations).sentences;
  };

export const getMatchedSentencesFromEvent =
  (eventId: UUID): Selector<string | null> =>
  (state): string | null => {
    const sentences = getEventWholeSetences(eventId)(state);
    if (!sentences || sentences.length === 0) {
      return null;
    }
    let totalNodes: AnnotatedSuperNodeT[] = [];
    sentences.forEach((sentence) => {
      totalNodes = totalNodes.concat(sentence.nodes);
    });
    const matchedText = Object.values(totalNodes).map((node) => node.nodes[0].text);
    return matchedText.join(' ');
  };

export const getEventWholeSentencesForGraph =
  (eventId: UUID): Selector<ReturnType<typeof annotateEventSentences> | null> =>
  (state): ReturnType<typeof annotateEventSentences> | null => {
    const event = getSingleEvent(state, eventId);
    if (event == null || event.annotations == null || event.sentences == null) {
      return null;
    }

    return annotateEventSentences(event.sentences, event.annotations);
  };

export const getRawCorrespondence =
  (eventId: UUID): Selector<string | null> =>
  ({ events: stateEvents }): string | null => {
    const event = stateEvents.events[eventId];
    if (event == null || event.communication == null) {
      return null;
    }

    return event.communication.body;
  };

export const getRawThreadContext =
  (eventId: UUID): Selector<API.Communications.ThreadContext['context'] | null> =>
  ({ events: stateEvents }): API.Communications.ThreadContext['context'] | null => {
    const event = stateEvents.events[eventId];
    if (event == null || event.communication == null) {
      return null;
    }

    return event.communication.context || null;
  };

export const getEventsLabels: Selector<string[]> = (state) => {
  const user = getUser(state);
  const policy = getPermissionsPolicy(state);

  if (isFieldUnprotected('events.hideLabel', user.roles, policy)) {
    return state.events.labels;
  }

  return state.events.labels.filter((label) => label !== 'hide');
};

export const getSingleEventLabels =
  (eventId: UUID): Selector<Action[]> =>
  (state): Action[] => {
    const actions = state.events.actions[eventId];
    if (actions == null) {
      return [];
    }

    return actions.filter((action) => action.type === 'label');
  };

export const getEventFullMessageHighlighted =
  (eventId: UUID): Selector<ReturnType<typeof annotateCommunicationGroups>['lines'] | null> =>
  ({ events: stateEvents }): ReturnType<typeof annotateCommunicationGroups>['lines'] | null => {
    const event = stateEvents.events[eventId];

    if (
      event == null ||
      event.communication == null ||
      event.communication.document == null ||
      event.communication.document === 'PII REDACTED' ||
      event.annotations == null
    ) {
      return null;
    }

    return annotateCommunicationGroups(
      event.communication.document.graphs,
      event.communication.document.groups,
      event.annotations
    ).lines;
  };

export type AnnotationWithColor = Annotation & {
  highlight?: boolean;
  underline?: boolean;
  rule_uuid?: string;
};

export const getCommsFullMessageHighlighted: Selector<
  ReturnType<typeof annotateCommunicationGroups>['lines'] | null,
  [UUID, string, string]
> = createSelector(
  [
    (state: GlobalState): GlobalState['events']['events'] => state.events.events,
    (state: GlobalState): GlobalState['communication']['communications'] =>
      state.communication.communications,
    getSurroundingContextRulesIds,
    getSelectedRule,
    getSelectedCampaign,
    getCampaignsRulesForHistory,
    getSelectedSentence,
    getHoverIdentifier,
    getSelectedModel,
    (_state, commId): string => commId,
    (_state, _commId, highRisk): string => highRisk,
    (_state, _commId, _highRisk, underlineOnly): string => underlineOnly,
    (state: GlobalState): GlobalState['envelopeReview']['selectedReviewer'] =>
      state.envelopeReview.selectedReviewer,
  ],
  (
    evs,
    comms,
    // special rules per customer config
    surroundingContextRules,
    selectedRule,
    selectedCampaign,
    // campaigns-rules present in the envelope
    campaignsRules,
    // Model transparency - selected sentence
    selectedSentence,
    // Model transparency - hovering identifier
    hoverIdentifier,
    // Model transparency - model branch selected
    selectedModel,
    commId,
    // For history section we only want high risk rules
    highRisk,
    // In case we only want to underline for a especific section
    underlineOnly,
    selectedReviewer
  ) => {
    // highlight is yellow, underline is gray

    const highRiskOnly = highRisk === 'true';
    const onlyUnderline = underlineOnly === 'true';

    const shouldFilter = selectedReviewer !== defaultReviewer.value;

    // shaping the high risk rules
    let highRiskRules: string[] = [];
    if (campaignsRules) {
      highRiskRules = Object.values(campaignsRules).reduce<string[]>(
        (acc, value) => [...acc, ...value.rules.map((r) => r.uuid)],
        []
      );
    }

    const events = Object.values(evs)
      .filter((e) => e.communication_uuid === commId)
      .filter((e) => e.tenant_name !== 'modeler' || !shouldFilter);
    const communication = comms[commId];

    // if no events, but we have comm docs, we can render without annotations
    if (
      (events == null || events.length === 0) &&
      communication &&
      communication.document &&
      communication.document !== 'PII REDACTED'
    ) {
      return annotateCommunicationGroups(
        communication.document.graphs,
        communication.document.groups,
        []
      ).lines;
    }

    // if no events and no comm docs, we don't render
    if (
      events == null ||
      events.length === 0 ||
      communication == null ||
      communication.document == null ||
      communication.document === 'PII REDACTED' ||
      events[0].annotations == null
    ) {
      return null;
    }

    const annotations: AnnotationWithColor[] = [];

    // First pass - General
    // if there is a selected rule, we want to only highlights those sentences
    // we also check if highRiskOnly mode is on
    if (selectedRule) {
      events.forEach((e) => {
        if (e.annotations) {
          if (e.rule_uuid === selectedRule?.uuid || e.rule_uuid === selectedRule?.rule_group_uuid) {
            e.annotations.forEach((a) => {
              if (surroundingContextRules.includes(e.rule_uuid ?? '')) {
                annotations.push({
                  ...a,
                  highlight: false,
                  underline: false,
                  rule_uuid: e.rule_uuid,
                });
              } else if (
                !highRiskOnly ||
                /* @ts-ignore */
                (highRiskOnly && highRiskRules.includes(e.rule_revision_uuid || ''))
              ) {
                annotations.push({
                  ...a,
                  highlight: true,
                  rule_uuid: e.rule_uuid,
                });
              }
            });
          }
        }
      });

      events.forEach((e) => {
        if (e.annotations) {
          /* @ts-ignore */
          if (e.rule_revision_uuid !== selectedRule?.uuid) {
            e.annotations.forEach((a) => {
              if (surroundingContextRules.includes(e.rule_uuid ?? '')) {
                annotations.push({
                  ...a,
                  highlight: false,
                  underline: false,
                  rule_uuid: e.rule_uuid,
                });
              } else if (
                !highRiskOnly ||
                /* @ts-ignore */
                (highRiskOnly && highRiskRules.includes(e.rule_revision_uuid || ''))
              ) {
                annotations.push({
                  ...a,
                  underline: true,
                  rule_uuid: e.rule_uuid,
                });
              }
            });
          }
        }
      });
    }
    // if there is no selected rule, we instead highlight by campaign
    // we also check if highRiskOnly mode is on
    else {
      events.forEach((e) => {
        if (e.annotations) {
          if (
            e.campaign_uuid === selectedCampaign &&
            /* @ts-ignore */
            (!highRiskOnly || (highRiskOnly && highRiskRules.includes(e.rule_revision_uuid || '')))
          ) {
            e.annotations.forEach((a) => {
              annotations.push({
                ...a,
                highlight: true,
                rule_uuid: e.rule_uuid,
              });
            });
          }
        }
      });

      events.forEach((e) => {
        if (e.annotations) {
          if (
            e.campaign_uuid !== selectedCampaign &&
            /* @ts-ignore */
            (!highRiskOnly || (highRiskOnly && highRiskRules.includes(e.rule_revision_uuid || '')))
          ) {
            e.annotations.forEach((a) => {
              annotations.push({
                ...a,
                underline: true,
                rule_uuid: e.rule_uuid,
              });
            });
          }
        }
      });
    }

    // Second pass - if onlyUnderline is on
    if (onlyUnderline) {
      annotations.forEach((a) => {
        if (a.highlight === true) {
          a.highlight = false;
          a.underline = true;
        }
      });
    }

    const { lines } = annotateCommunicationGroups(
      communication.document.graphs,
      communication.document.groups,
      annotations
    );

    // Third pass - Model Transparency
    // If model transparency is on, we modify the annotations
    if (selectedSentence.index !== -1 && selectedSentence.communicationId === commId) {
      // If we are hovering over an identifier, we highlight only those annotations
      if (hoverIdentifier) {
        lines.forEach((l) => {
          if (l.index === selectedSentence.index) {
            l.nodes.forEach((n) => {
              if (n.annotations) {
                n.annotations.forEach((a, idx) => {
                  if (n.annotations?.[idx]) {
                    if (a.uuid === hoverIdentifier) {
                      n.annotations[idx] = { ...a, highlight: true, underline: false };
                    } else {
                      n.annotations[idx] = { ...a, highlight: false, underline: true };
                    }
                  }
                });
              }
            });
          }
        });
      }
      // If there is a selected model, we highlight only those annotations
      else if (selectedModel.length > 0) {
        lines.forEach((l) => {
          if (l.index === selectedSentence.index) {
            l.nodes.forEach((n) => {
              if (n.annotations) {
                n.annotations.forEach((a, idx) => {
                  if (n.annotations?.[idx]) {
                    if (a.uuid && selectedModel.includes(a.uuid)) {
                      n.annotations[idx] = { ...a, highlight: true, underline: false };
                    } else {
                      n.annotations[idx] = { ...a, highlight: false, underline: true };
                    }
                  }
                });
              }
            });
          }
        });
      }
      // we only highlight what corresponds to the selected sentence
      else {
        lines.forEach((l) => {
          if (l.index === selectedSentence.index) {
            l.nodes.forEach((n) => {
              if (n.annotations) {
                n.annotations.forEach((a, idx) => {
                  if (n.annotations?.[idx]) {
                    n.annotations[idx] = { ...a, highlight: true, underline: false };
                  }
                });
              }
            });
          } else {
            l.nodes.forEach((n) => {
              if (n.annotations) {
                n.annotations.forEach((a, idx) => {
                  if (n.annotations?.[idx]) {
                    n.annotations[idx] = { ...a, highlight: false, underline: true };
                  }
                });
              }
            });
          }
        });
      }
    }

    return lines;
  }
);

export const getEventsTotalCount: Selector<number> = (state) => state.events.count;

export const getEventCommUuid =
  (eventId: UUID): Selector<UUID | null> =>
  ({ events: stateEvents }): UUID | null => {
    const event = stateEvents.events[eventId];
    if (!event) {
      return null;
    }

    return event.communication_uuid;
  };

export const getEventsByEnvelope =
  (envelopeId: UUID): Selector<Event[] | null> =>
  ({ events: stateEvents }): Event[] | null => {
    const events = Object.values(stateEvents.events);
    if (!events) {
      return null;
    }

    return events
      .filter((e) => e.tenant_name !== 'modeler')
      .filter((event: Event) => event.communication_envelope_uuid === envelopeId)
      .sort((a: Event, b: Event) => {
        if (a.created_at == null || b.created_at == null) {
          return 0;
        }

        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB.getTime() - dateA.getTime();
      });
  };

export const getPlatformNames: Selector<
  {
    uuid: string;
    name: string;
  }[]
> = createSelector(
  [(state: GlobalState): string[] => state.campaign.activeIntegrations],
  (activePlatforms) =>
    Object.entries({
      ...platformNames,
    })
      .filter(([key]) => activePlatforms.includes(key))
      .map(([key, value]) => ({
        uuid: key,
        name: value,
      }))
);

export const getPlatformCount: Selector<number> = (state) => {
  const activePlatforms = state.campaign.activeIntegrations;

  return Object.entries({
    ...platformNames,
  }).filter(([key]) => activePlatforms.includes(key)).length;
};

export const getKeyActionsNames: Selector<
  {
    uuid: string;
    name: string;
  }[]
> = createSelector([], () => {
  const keyActionsValues: { uuid: string; name: string }[] = [];
  Object.entries(keyActions).forEach(([key, value]) => {
    keyActionsValues.push({ uuid: key, name: value });
  });

  return keyActionsValues;
});

export const getEventsByCommunication =
  (communicationId: UUID, envelopeId: UUID): Selector<Event[] | null> =>
  ({ envelopes }): Event[] | null => {
    const envelope = envelopes.envelopes[envelopeId];

    if (!envelope || !envelope.communications) return null;

    let events: Event[] | null = [];
    if (envelope.events) {
      events = envelope.events.filter((e) => communicationId === e.communication_uuid);
    }

    return events;
  };

export const getEventsByEnvelopeId =
  (envelopeId: UUID): Selector<Event[] | null> =>
  ({ envelopes }): Event[] | null => {
    const envelope = envelopes.envelopes[envelopeId];

    if (!envelope) return null;
    return envelope.events || null;
  };

export const getRulesByEnvelope =
  (envelopeId: UUID): Selector<Rule[] | null> =>
  ({ envelopes }): Rule[] | null => {
    const envelope = envelopes.envelopes[envelopeId];

    if (!envelope || !envelope.communications) return null;

    let rules: Rule[] | null = [];
    if (envelope.events) {
      rules = envelope.events.reduce((result: Rule[], e) => {
        if (e.rule) {
          result.push(e.rule);
        }
        return result;
      }, []);

      rules = rules.filter((r, index, arr) => index === arr.findIndex((ru) => ru.uuid === r.uuid));
    }

    return rules;
  };

export const getSelectedEvent: Selector<Event | null> = (state) => state.events.selectedEvent;
