import { createAction } from '@reduxjs/toolkit';
import type {
  Attachment,
  Communication,
  CommunicationDocumentV2,
  CommunicationSummary,
  CommunicationWithEvents,
  ErrorObject,
  Event,
  MModel,
  Platform,
  RuleIdentifier,
  TestCommunication,
  UUID,
} from 'types';

type PostTestSentencePayload = {
  body: string;
  rules?: UUID[];
  campaigns?: UUID[];
  annotators?: UUID[];
  platforms: Platform[];
};

export type PostTestSentencePayloadV2 = {
  body: string;
  models?: Partial<MModel>[];
  identifiers?: Partial<RuleIdentifier>[];
  platform: string;
  subject?: string;
  inRuleGroup?: boolean;
  force_v2_graphs?: boolean;
  customer_uuid?: string;
};

export const fetchCommunicationSummary = createAction<{ commId: UUID }>(
  'COMMUNICATION_SUMMARY/FETCH'
);
export const fetchCommunicationSummaryRequest = createAction('COMMUNICATION_SUMMARY/FETCH_REQUEST');
export const fetchCommunicationSummarySuccess = createAction<CommunicationSummary>(
  'COMMUNICATION_SUMMARY/FETCH_SUCCESS'
);
export const fetchCommunicationSummaryFailure = createAction<ErrorObject>(
  'COMMUNICATION_SUMMARY/FETCH_FAILURE'
);

export const postTestSentence = createAction<PostTestSentencePayload>(
  'COMMUNICATION/POST_TEST_SENTENCE'
);
export const postTestSentenceV2 = createAction<PostTestSentencePayloadV2>(
  'COMMUNICATION/POST_TEST_SENTENCE_V2'
);
export const testSentenceRequest = createAction('COMMUNICATION/TEST_SENTENCE_REQUEST');
export const testSentenceSuccess = createAction<TestCommunication>(
  'COMMUNICATION/TEST_SENTENCE_SUCCESS'
);
export const testSentenceFailure = createAction<ErrorObject>('COMMUNICATION/TEST_SENTENCE_FAILURE');

export const clearTestSentence = createAction('COMMUNICATION/CLEAR_TEST_SENTENCE');

type PostEvaluateTestSentencePayload = {
  body: string;
  platform: Platform;
};
export const postEvaluateTestSentence = createAction<PostEvaluateTestSentencePayload>(
  'COMMUNICATION/EVALUATE_TEST_SENTENCE'
);
export const evaluateTestSentenceRequest = createAction(
  'COMMUNICATION/EVALUATE_TEST_SENTENCE_REQUEST'
);
export const evaluateTestSentenceSuccess = createAction(
  'COMMUNICATION/EVALUATE_TEST_SENTENCE_SUCCESS'
);
export const evaluateTestSentenceFailure = createAction<ErrorObject>(
  'COMMUNICATION/EVALUATE_TEST_SENTENCE_FAILURE'
);

type TestAnnotatorPayload = {
  annotator: UUID;
  body: string;
};

export const testAnnotator = createAction<TestAnnotatorPayload>('COMMUNICATION/TEST_ANNOTATOR');

export type selectCommunicationType = {
  communication: (Communication & { events?: Event[]; attachments?: Attachment[] }) | null;
};

export const selectCommunication = createAction<selectCommunicationType>('COMMUNICATION/SELECT');

export const selectTranslation = createAction<selectCommunicationType>(
  'COMMUNICATION/SELECT_TRANSLATION'
);

type fetchAttachmentsType = {
  commUuid: UUID;
};

export const fetchAttachments = createAction<fetchAttachmentsType>(
  'COMMUNICATION/FETCH_ATTACHMENT'
);
export const fetchAttachmentsRequest = createAction<fetchAttachmentsType>(
  'COMMUNICATION/FETCH_ATTACHMENT_REQUEST'
);
export const fetchAttachmentsSuccess = createAction<Attachment[]>(
  'COMMUNICATION/FETCH_ATTACHMENT_SUCCESS'
);
export const fetchAttachmentsFailure = createAction<ErrorObject>(
  'COMMUNICATION/FETCH_ATTACHMENT_FAILURE'
);
export const fetchAttachmentsFulfill = createAction('COMMUNICATION/FETCH_ATTACHMENT_FULFILL');

export const toggleShowV2Graph = createAction('COMMUNICATION/TOGGLE_SHOW_V2');

type FetchCommunicationDocumentPayload = { communication: Communication };
export const fetchCommunicationDocument =
  createAction<FetchCommunicationDocumentPayload>('COMMUNICATION/FETCH');
export const fetchCommunicationDocumentRequest = createAction('COMMUNICATION/FETCH_REQUEST');

export type FetchCommunicationDocumentSuccessPayload = {
  communication: Communication;
  document: CommunicationDocumentV2;
};
export const fetchCommunicationDocumentSuccess =
  createAction<FetchCommunicationDocumentSuccessPayload>('COMMUNICATION/FETCH_SUCCESS');
export const fetchCommunicationDocumentFailure = createAction<ErrorObject>(
  'COMMUNICATION/FETCH_FAILURE'
);

type FetchAllCommunicationDocumentPayload = { communications: CommunicationWithEvents[] };
export const fetchAllCommunicationDocument =
  createAction<FetchAllCommunicationDocumentPayload>('COMMUNICATION/FETCH_ALL');
export const fetchAllCommunicationDocumentRequest = createAction('COMMUNICATION/FETCH_ALL_REQUEST');
export const fetchAllCommunicationDocumentSuccess = createAction('COMMUNICATION/FETCH_ALL_SUCCESS');
export const fetchAllCommunicationDocumentFailure = createAction<ErrorObject>(
  'COMMUNICATION/FETCH_ALL_FAILURE'
);
