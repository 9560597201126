/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { BarDatum } from '@nivo/bar';
import { Serie } from '@nivo/line';
import {
  allowedCategories,
  allowedDimensions,
  DEFAULT_FILTER_OPTIONS,
  iconsMap,
  newIconsMap,
  tableRowTitleMap,
} from 'constants/dashboard';
import { TREND_ICON } from 'constants/dashboardIcons';
import {
  axisUserPropsDefault,
  formatFunctionDefaults,
  NivoBarPropsDefault,
  NivoBarUserPropsDefault,
} from 'constants/nivoPropsDefaults';
import { camelCase, capitalize, isArray, isObject, transform, uniq } from 'lodash';
import type {
  AxisUserProps,
  DashboardConfig,
  DashboardDimension,
  DashboardFilter,
  DashboardMetric,
  DashboardWidget,
  IntersectedError,
  Metric,
  MetricsData,
  MetricsDataV2,
  NivoArgsProps,
  NivoBarData,
  NivoBarProps,
  NivoBarUserProps,
  QueryMetricObject,
  RouteParams,
} from 'types';
import {
  getLabelFormatFromPeriod,
  kFormatter,
  removeUTC,
  sumObjectsByKey,
  timeRangeToMetricsRange,
} from 'utils/timeRanges';

export const areIntersected = (config: DashboardConfig): IntersectedError | null => {
  const { widgets } = config;
  for (let i = 0; i < widgets.length; i += 1) {
    for (let j = i + 1; j < widgets.length; j += 1) {
      const AColStart = widgets[i].columns_start;
      const AColEnd = widgets[i].columns_start + widgets[i].columns_width;
      const BColStart = widgets[j].columns_start;
      const BColEnd = widgets[j].columns_start + widgets[j].columns_width;
      const ARowStart = widgets[i].rows_start;
      const ARowEnd = widgets[i].rows_start + widgets[i].rows_height;
      const BRowStart = widgets[j].rows_start;
      const BRowEnd = widgets[j].rows_start + widgets[j].rows_height;
      if (
        AColStart < BColEnd &&
        BColStart < AColEnd &&
        ARowStart < BRowEnd &&
        BRowStart < ARowEnd
      ) {
        return {
          Ax1: AColStart,
          Ax2: AColEnd,
          Ay1: ARowStart,
          Ay2: ARowEnd,
          Bx1: BColStart,
          Bx2: BColEnd,
          By1: BRowStart,
          By2: BRowEnd,
          widget1: widgets[i].widget,
          widget2: widgets[j].widget,
        };
      }
    }
  }

  return null;
};

export const boundariesValidation = (
  widgetConfig: DashboardWidget,
  minWidth?: number,
  minHeight?: number
): string | null => {
  const { columns_width, rows_height, widget } = widgetConfig;

  if (minWidth != null) {
    if (columns_width < minWidth) {
      return `Error, ${widget} must have a min width of ${minWidth}`;
    }
  }

  if (minHeight != null) {
    if (rows_height < minHeight) {
      return `Error, ${widget} must have a min height of ${minHeight}`;
    }
  }

  if (columns_width > 18) {
    return `Error, ${widget} cannot have more than 16 columns of width`;
  }

  return null;
};

export const buildQFromDashboardConfig = (
  metrics: DashboardMetric[],
  timeRange: string,
  fetchOnePeriod: boolean,
  fetchAvgPeriod: boolean,
  metricType?: string,
  filters?: RouteParams,
  widgetFilters?: RouteParams,
  unitOfTime?: string,
  use_cache?: boolean,
  dashboardConfig?: DashboardConfig & {
    key: string;
  },
  customer_uuids?: string[]
): {
  start_time: number;
  end_time: number;
  queries: DashboardMetric[];
  time_range?: string;
  tz?: string;
  use_cache?: boolean;
  customer_uuids: string[];
} => {
  let {
    // eslint-disable-next-line prefer-const
    start,
    // eslint-disable-next-line prefer-const
    end,
    unitOfTime: rangeMathParamUnitOfTime,
  } = timeRangeToMetricsRange(
    timeRange,
    fetchOnePeriod,
    fetchAvgPeriod,
    unitOfTime,
    ['sent_at', 'reviewed_at'].includes(metricType || '')
  );

  if (dashboardConfig?.override_time_range?.includes('months')) {
    rangeMathParamUnitOfTime = 'months';
  }

  const dashFilters = dashboardConfig?.filters;

  const dimensions: DashboardDimension[] = [];

  if (filters != null) {
    Object.entries(filters).forEach(([name, value]) => {
      const filter = dashFilters?.find((fltr) => fltr.dimension === name);
      const operator = filter?.options?.find((opt) =>
        Object.keys(opt).some((key) => key === 'logical_operator')
      );
      if (Array.isArray(value)) {
        value.forEach((element) => {
          if (operator) {
            dimensions.push({ name, value: element, logical_operator: operator.logical_operator });
          } else {
            dimensions.push({ name, value: element });
          }
        });
      } else if (operator) {
        dimensions.push({ name, value, logical_operator: operator.logical_operator });
      } else {
        dimensions.push({ name, value });
      }
    });
  }
  if (widgetFilters != null) {
    Object.entries(widgetFilters).forEach(([name, value]) =>
      dimensions.push({ name, value: Array.isArray(value) ? value[0] : value })
    );
  }

  const metricsData = metrics.map((metric) => {
    // this filters out unwanted dimensions coming from the ui filters according
    // to the metric
    const extraDimensions = [...dimensions].filter((dimension) => {
      const allowed = allowedDimensions[metric.metric];

      if (metricType && ['sent_at', 'reviewed_at', 'inactive_users'].includes(metricType)) {
        return true;
      }

      if (allowed != null) {
        return allowed.includes(dimension.name);
      }

      return false;
    });

    const categories = [...dimensions].filter((dimension) => {
      // TODO: abstract the <metric>_group_by out here or something like that
      const allowed = allowedCategories[metric.metric];

      if (allowed != null) {
        return allowed.includes(dimension.name);
      }

      return false;
    });

    // replace any metric level dimensions with overrides from the ui filters and nav params
    const mergedDimensions = [...(metric.dimensions ?? []), ...extraDimensions].reduce(
      (acc, dim) => {
        const found = extraDimensions.filter((extraDim) => extraDim.name === dim.name);
        if (found.length > 0) {
          return Array.from(new Set([...acc, ...found]));
        }

        return Array.from(new Set([...acc, dim]));
      },
      [] as DashboardDimension[]
    );

    const navParamUnitOfTime = unitOfTime;
    // either use filter, use range determined from time span lenght, or use metric definition
    const UOT = navParamUnitOfTime || rangeMathParamUnitOfTime || metric.unit_of_time;

    // get new range if unit of time has changed
    const metricsRange = timeRangeToMetricsRange(
      timeRange,
      fetchOnePeriod,
      fetchAvgPeriod,
      UOT,
      ['sent_at', 'reviewed_at'].includes(metricType || '')
    );
    return {
      ...metric,
      group_by: categories.map((cat) => cat.value)[0] || metric.group_by,
      dimensions: mergedDimensions,
      period: metricsRange.period,
      // @ts-ignore
      type: metric.type || metricType,
      unit_of_time: UOT,
    };
  });

  return {
    start_time: start / 1000,
    end_time: end / 1000,
    queries: metricsData,
    // NOTE: time_range and tz are only used for global caching
    time_range: timeRange,
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    use_cache,
    customer_uuids: customer_uuids || [],
  };
};

export type DateHistogramDataReturnType = {
  keys: string[];
  labelsMap: { [key: string]: string };
  data: NivoBarData[];
  indexBy: string;
  original?: { [key: string]: string }[];
};

export type TableDataReturnType = {
  headers: string[];
  labelsMap: { [key: string]: string };
  data: NivoBarData[];
  original?: { [key: string]: string }[];
  total?: (string | number)[];
  firstColName?: string;
};

export type CollapseTableDataReturnType = {
  keys: string[];
  labelsMap: { [key: string]: string };
  data: { [key: string]: { [key: string]: { [key: string]: number } } };
  rowData: NivoBarData[];
};

export const buildDateHistogramDataFromMetrics = (
  widgetData: MetricsData,
  queryData: QueryMetricObject
): DateHistogramDataReturnType => {
  const formattedData: NivoBarData[] = [];
  const keys: string[] = [];
  let labelsMap: { [key: string]: string } = {};

  widgetData.results.forEach((metric, idx) => {
    keys.push(metric.id);
    labelsMap = { ...labelsMap, [metric.id]: metric.label };
    metric.x_axis.forEach((x, index) => {
      formattedData[index] = {
        ...formattedData[index],
        date: getLabelFormatFromPeriod({
          timestamp: x.value as number,
          period: queryData.queries[idx].period as number,
          unitOfTime: queryData.queries[idx].unit_of_time as string,
        }),
        [metric.id]: metric.y_axis[index],
      };
    });
  });

  return { keys: uniq(keys), labelsMap, data: formattedData, indexBy: 'date' };
};

export const buildStackedDateHistogramDataFromMetricsV2 = (
  widgetData: MetricsDataV2,
  queryData: QueryMetricObject,
  percentScale: boolean
): DateHistogramDataReturnType => {
  const formattedDataScaled: BarDatum[] = [];

  const keys: string[] = [];
  let labelsMap: { [key: string]: string } = {};

  let names;
  if (widgetData.results) {
    widgetData.results.forEach((metric, idx) => {
      keys.push(metric.id);
      labelsMap = { ...labelsMap, [metric.id]: metric.label };

      names = metric.y_axis.reduce((acc, item) => [...acc, item.id] as never[], []);

      metric.x_axis.forEach((x, index) => {
        const originalData: { [key: string]: number } = {};
        const scaledData: { [key: string]: number } = {};
        /* let existsIndexData: BarDatum | undefined; */

        const total = metric.y_axis.reduce((acc, curr) => acc + curr.values[index], 0);

        metric.y_axis.forEach((item) => {
          originalData[item.id] = item.values[index];
          scaledData[item.id] = item.values[index] === 0 ? 0 : item.values[index] / total;
          labelsMap = { ...labelsMap, [item.id]: item.label };
        });

        let x_value = getLabelFormatFromPeriod({
          timestamp:
            Date.parse(
              // remove Z from date string if exist to avoid parsing as UTC
              removeUTC({ date: x.value, unitOfTime: queryData.queries[idx].unit_of_time })
            ) / 1000,
          period: queryData.queries[idx].period,
          unitOfTime: queryData.queries[idx].unit_of_time,
          utc: queryData.queries[idx].unit_of_time !== 'hours',
        });

        const x_exists = formattedDataScaled.some(({ date }) => date === x_value);

        if (x_exists) {
          // eslint-disable-next-line no-param-reassign
          x_value = ` ${x_value}`;
          /* index = formattedDataScaled.findIndex(({ date }) => date === x_value);
          existsIndexData = formattedDataScaled.find(({ date }) => date === x_value);
          formattedDataScaled[index] = sumObjectsByKey(originalData, existsIndexData as BarDatum); */
        }

        formattedDataScaled.push({
          // v2 returns date string so we convert to number here
          date: x_value,
          ...(percentScale ? scaledData : originalData),
        });
      });
    });
  }

  return {
    keys: uniq(names),
    labelsMap,
    data: formattedDataScaled,
    indexBy: 'date',
  };
};

export const buildCollapseTableDataFromMetricsV2 = (
  widgetData: MetricsDataV2,
  queryData: QueryMetricObject
): CollapseTableDataReturnType => {
  let formattedDataScaled: { [key: string]: { [key: string]: { [key: string]: number } } } = {};

  const keys: string[] = [];
  let labelsMap: { [key: string]: string } = {};

  const metricsV1 = ['review-sets-pending', 'review-sets-total'];

  const data = [...widgetData.results];
  const collapseData = data.filter((metric) => !metricsV1.includes(metric.id));

  const dataRow = data.filter((metric) => metricsV1.includes(metric.id));
  const formattedDataRow: NivoBarData[] = [];
  const rowMap: {
    [key: string]: NivoBarData;
  } = {};

  if (dataRow) {
    (dataRow as unknown as Metric[]).forEach((metric) => {
      metric.x_axis.forEach((x, index) => {
        rowMap[x.value] = {
          ...rowMap[x.value],
          user: x.label,
          [metric.label]: metric.y_axis[index],
        };
      });
    });

    Object.keys(rowMap).forEach((key) => {
      formattedDataRow.push(rowMap[key]);
    });
  }

  if (collapseData) {
    collapseData.forEach((metric, idx) => {
      keys.push(queryData.queries[idx].label);
      labelsMap = { ...labelsMap, [metric.id]: queryData.queries[idx].label };

      metric.x_axis.forEach((x, index) => {
        labelsMap = { ...labelsMap, [x.value]: x.label };
        const originalData: { [key: string]: { [key: string]: number } } = {};

        metric.y_axis.forEach((item) => {
          originalData[item.id] = { [metric.label]: item.values[index] };

          const user = x.label;
          const userExists = user in formattedDataScaled;
          if (userExists) {
            const existsUserData = formattedDataScaled[user];
            const existsItem = item.label in existsUserData;
            if (existsItem) {
              const itemData = existsUserData[item.label];
              formattedDataScaled[user][item.label] = {
                ...itemData,
                [queryData.queries[idx].label]: item.values[index],
              };
              return;
            }
            formattedDataScaled[user] = {
              ...existsUserData,
              [item.label]: { [queryData.queries[idx].label]: item.values[index] },
            };
            return;
          }

          formattedDataScaled = {
            ...formattedDataScaled,
            [user]: { [item.label]: { [queryData.queries[idx].label]: item.values[index] } },
          };
        });
      });
    });
  }

  keys.unshift('Reviewer', 'Open Review Sets');

  return {
    keys,
    labelsMap,
    data: formattedDataScaled,
    rowData: formattedDataRow,
  };
};

export const buildTableDataFromMetrics = (
  widgetData: MetricsDataV2 | MetricsData,
  queryData: QueryMetricObject
): TableDataReturnType => {
  const formattedDataScaled: BarDatum[] = [];
  const formattedData: NivoBarData[] = [];
  let total: (string | number)[] = [];

  const keys: string[] = [];
  let labelsMap: { [key: string]: string } = {};
  let headers;

  if (typeof widgetData.results[0].y_axis[0] === 'object') {
    const data = widgetData as MetricsDataV2;

    data.results.forEach((metric, idx) => {
      keys.push(metric.id);
      labelsMap = { ...labelsMap };

      headers = metric.y_axis?.reduce(
        (
          acc: never[],
          item: {
            id: string;
            values: number[];
            label: string;
          }
        ) => [...acc, item.id] as never[],
        []
      );

      metric.x_axis.forEach((x, index) => {
        const originalData: { [key: string]: number } = {};

        let existsIndexData: BarDatum | undefined;

        metric.y_axis.forEach((item) => {
          originalData[item.id] = item.values[index];
          labelsMap = { ...labelsMap, [item.id]: item.label };
        });

        const x_value = getLabelFormatFromPeriod({
          timestamp:
            Date.parse(
              // remove Z from date string if exist to avoid parsing as UTC
              removeUTC({ date: x.value, unitOfTime: queryData.queries[idx].unit_of_time })
            ) / 1000,
          period: queryData.queries[idx].period,
          unitOfTime: queryData.queries[idx].unit_of_time,
          customFormat: 'MMMM',
        });

        const x_exists = formattedDataScaled.some(({ date }) => date === x_value);

        if (x_exists) {
          // eslint-disable-next-line no-param-reassign
          index = formattedDataScaled.findIndex(({ date }) => date === x_value);
          existsIndexData = formattedDataScaled.find(({ date }) => date === x_value);
          formattedDataScaled[index] = sumObjectsByKey(originalData, existsIndexData as BarDatum);
        }

        formattedDataScaled.push({
          // v2 returns date string so we convert to number here
          date: x_value,
          ...originalData,
        });
      });
    });
    total = [
      ...Object.keys(labelsMap).map((k) =>
        formattedDataScaled.reduce((acc, key) => acc + (key[k] as number), 0)
      ),
    ];
  } else {
    const data = widgetData as MetricsData;

    data.results.forEach((metric, idx) => {
      keys.push(metric.id);
      labelsMap = { ...labelsMap, [metric.id]: metric.label };

      metric.x_axis.forEach((x, index) => {
        formattedData[index] = {
          ...formattedData[index],
          date: getLabelFormatFromPeriod({
            timestamp: x.value as number,
            period: queryData.queries[idx].period as number,
            unitOfTime: queryData.queries[idx].unit_of_time as string,
            customFormat: 'MMMM',
            utc: true,
          }),
          [metric.id]: metric.y_axis[index],
        };
      });
    });

    const totalKeys = keys;

    total = [
      ...totalKeys.map((k) =>
        k === 'percent-flaggedForReview'
          ? `${(
              (formattedData.reduce((acc, key) => acc + (key[totalKeys[1]] as number), 0) /
                formattedData.reduce((acc, key) => acc + (key[totalKeys[0]] as number), 0)) *
              100
            ).toLocaleString()}%`
          : formattedData.reduce((acc, key) => acc + (key[k] as number), 0)
      ),
    ];
  }

  return {
    headers: uniq(headers),
    labelsMap,
    data: formattedDataScaled.length ? formattedDataScaled : formattedData,
    total,
  };
};

export const buildTableDataFromMetricsV2 = (
  widgetData: MetricsDataV2 | MetricsData,
  queryData: QueryMetricObject
): TableDataReturnType => {
  const formattedData: BarDatum[] = [];
  let labelsMap: { [key: string]: string } = {};
  const headers: string[] = [];

  const groupBy = queryData.queries[0].group_by
    ? tableRowTitleMap[queryData.queries[0].group_by]
    : queryData.queries[0].id === 'inactive_chat'
    ? 'Connection Issue'
    : '';

  const commFlagged = queryData.queries.some((query) => query.metric === 'communications_flagged');

  const allKeys = [
    ...new Set(
      // @ts-ignore
      widgetData.results.reduce(
        // @ts-ignore
        (acc, el) => [
          ...acc,
          // @ts-ignore
          ...el.x_axis.reduce((i_acc, i_el) => {
            if (i_el.value) {
              return [...i_acc, i_el.value];
            }
            return i_acc;
          }, []),
        ],
        []
      )
    ),
  ];

  // map to give zero as a default
  widgetData.results.forEach((metric) => {
    allKeys.forEach((key) => {
      const x_exists = formattedData.some(({ rowId }) => rowId === key);
      const queryMetric = queryData.queries.find((query) => query.id === metric.id);
      const metricId =
        queryMetric?.metric &&
        ['min_time', 'communication_start_date'].includes(queryMetric?.metric as string)
          ? queryMetric?.metric
          : metric.id;

      if (x_exists) {
        const index = formattedData.findIndex(({ rowId }) => rowId === key);
        const existsIndexData = formattedData.find(({ rowId }) => rowId === key);
        formattedData[index] = sumObjectsByKey(existsIndexData as BarDatum, {
          // @ts-ignore
          rowId: key,
          [metricId as string]: 0,
        });
        return;
      }

      formattedData.push({
        // @ts-ignore
        rowId: key,
        [metricId as string]: 0,
      });
    });
  });

  widgetData.results.forEach((metric) => {
    labelsMap = { ...labelsMap };
    headers.push(metric.label);

    metric.y_axis.forEach((x, index) => {
      const originalData: { [key: string]: number } = {};

      let existsIndexData: BarDatum | undefined;

      let x_value: string | number;

      const queryMetric = queryData.queries.find((query) => query.id === metric.id);

      const metricId =
        queryMetric?.metric &&
        ['min_time', 'communication_start_date'].includes(queryMetric?.metric)
          ? queryMetric?.metric
          : metric.id;

      // @ts-ignore
      if (!x.values) {
        // @ts-ignore
        originalData[metricId] = x;

        x_value = metric.x_axis[index].value;
        const x_label = metric.x_axis[index].label;

        const x_exists = formattedData.some(({ rowId }) => rowId === x_value);

        if (x_exists) {
          labelsMap = { ...labelsMap, [`${x_value}`]: `${x_label}` };
          const foundIndex = formattedData.findIndex(({ rowId }) => rowId === x_value);
          existsIndexData = formattedData.find(({ rowId }) => rowId === x_value);
          formattedData[foundIndex] = sumObjectsByKey(existsIndexData as BarDatum, originalData);
          return;
        }
        if (!x_value) {
          formattedData[index] = { ...formattedData[index], ...originalData };
          return;
        }
      } else {
        // @ts-ignore
        originalData[metricId] = x.values.reduce((acc, el) => acc + el, 0);
        // @ts-ignore
        labelsMap = { ...labelsMap, [x.id]: x.label };
        // @ts-ignore
        x_value = x.id;
        const x_exists = formattedData.some(({ rowId }) => rowId === x_value);

        if (x_exists) {
          // eslint-disable-next-line no-param-reassign
          const foundIndex = formattedData.findIndex(({ rowId }) => rowId === x_value);
          existsIndexData = formattedData.find(({ rowId }) => rowId === x_value);
          formattedData[foundIndex] = sumObjectsByKey(existsIndexData as BarDatum, originalData);
          return;
        }
      }

      formattedData.push({
        rowId: x_value,
        ...originalData,
      });
    });
  });

  if (commFlagged) {
    formattedData.sort((a, b) =>
      a['percent-flaggedForReview'] > b['percent-flaggedForReview'] ? -1 : 1
    );
  }

  headers.unshift(groupBy);

  return {
    headers,
    labelsMap,
    data: formattedData,
  };
};

export type DateHistogramLineDataReturnType = {
  keys: string[];
  labelsMap: { [key: string]: string };
  data: Serie[];
  indexBy: string;
  original?: { [key: string]: string }[];
};

export const buildStackedDateHistogramLineDataFromMetricsV2 = (
  widgetData: MetricsDataV2,
  queryData: QueryMetricObject
): DateHistogramLineDataReturnType => {
  const formattedDataScaled: Serie[] = [];

  const keys: string[] = [];
  let labelsMap: { [key: string]: string } = {};

  let names;
  widgetData.results.forEach((metric, idx) => {
    keys.push(metric.id);

    labelsMap = { ...labelsMap, [metric.id]: metric.label };
    names = metric.y_axis.reduce((acc, item) => [...acc, item.id] as never[], []);

    metric.y_axis.forEach((item) => {
      const i: { id: string; data: { x: string; y: number }[] } = {
        id: item.label,
        data: [],
      };

      item.values.forEach((value, index) => {
        let x_value = getLabelFormatFromPeriod({
          timestamp: Date.parse(metric.x_axis[index].value) / 1000,
          period: queryData.queries[idx].period,
          unitOfTime: queryData.queries[idx].unit_of_time,
        });
        // nivo line chart deletes duplicated x axis values
        // if there is a tick for 'Tue 08' from February and another
        // 'Tue 08' from March, nivo deletes the last one
        const x_exists = i.data.some(({ x }) => x === x_value);

        if (x_exists) {
          // adding whitespace to x_tick value avoids nivo deleting duplicated items
          x_value = ` ${x_value}`;
        }
        i.data.push({
          x: x_value,
          y: value,
        });
      });

      formattedDataScaled.push(i);
    });
  });

  return {
    keys: uniq(names),
    labelsMap,
    data: formattedDataScaled,
    indexBy: 'date',
  };
};

export const getTotalCountFromDateHistogramLineData = (
  processedData: DateHistogramLineDataReturnType
): number =>
  processedData.data.reduce((acc, serie) => {
    const dataTotal = serie.data.reduce((serieAcc, data) => {
      const value = data.y;
      if (typeof value === 'number') {
        return serieAcc + value;
      }

      return serieAcc;
    }, 0);

    return acc + dataTotal;
  }, 0);

export const getTotalCountFromDateHistogramData = (
  processedData: DateHistogramDataReturnType
): number =>
  processedData.keys.reduce((acc, key) => {
    const keyTotal = processedData.data.reduce((keyAcc, data) => {
      const value = data[key];
      if (typeof value === 'number') {
        return keyAcc + value;
      }

      return keyAcc;
    }, 0);

    return acc + keyTotal;
  }, 0);

export const getPeriodAverageFromDateHistogramData = (
  processedData: DateHistogramDataReturnType
): number =>
  Math.round(getTotalCountFromDateHistogramData(processedData) / processedData.data.length);

export const buildTrendDataFromMetrics = (
  data: MetricsData
): { count: string; difference?: number } | { error: string } => {
  if (data.results.length > 1) {
    return { error: 'You cannot use this widget with multiple metrics' };
  }
  if (data.results[0].y_axis.length === 1) {
    return { count: kFormatter(data.results[0].y_axis[0]) };
  }

  const count = data.results[0].y_axis[1];
  const pastPeriodCount = data.results[0].y_axis[0];

  return { count: kFormatter(count), difference: count - pastPeriodCount };
};

type HistogramDataReturnType = {
  keys: string[];
  labelsMap: { [key: string]: string };
  data: NivoBarData[];
  indexBy: string;
};
export const buildHistogramDataFromMetrics = (widgetData: MetricsData): HistogramDataReturnType => {
  const formattedData: NivoBarData[] = [];
  const keys: string[] = [];
  let labelsMap: { [key: string]: string } = {};
  widgetData.results.forEach((metric) => {
    keys.push(metric.id);
    labelsMap = { ...labelsMap, [metric.id]: metric.label };
    metric.x_axis.forEach((x, index) => {
      let { label } = x;

      if (metric.x_axis.some((name, idx) => name.label === x.label && index !== idx)) {
        label = `${label} (${index})`;
      } else if (metric.x_axis.some((name, idx) => name === x && index !== idx)) {
        label = `${label} (${index})`;
      }
      formattedData[index] = {
        ...formattedData[index],
        category: { value: x.value, label },
        [metric.id]: metric.y_axis[index],
      };
    });
  });
  return { keys: uniq(keys), labelsMap, data: formattedData, indexBy: 'category' };
};

type StackedHistogramDataReturnType = {
  keys: string[];
  labelsMap: { [key: string]: string };
  data: BarDatum[];
  indexBy: string;
};
export const buildStackedHistogramDataFromMetrics = (
  widgetData: MetricsDataV2
): StackedHistogramDataReturnType => {
  const formattedData: BarDatum[] = [];
  const keys: string[] = [];
  let labelsMap: { [key: string]: string } = {};
  widgetData.results.forEach((metric) => {
    metric.x_axis.forEach((x, index) => {
      metric.y_axis.forEach((y) => {
        keys.push(y.id);
        labelsMap = { ...labelsMap, [y.id]: y.label };
        formattedData[index] = {
          ...formattedData[index],
          // @ts-ignore
          category: { value: x.value, label: x.label },
          [y.id]: y.values[index],
        };
      });
    });
  });

  return { keys: uniq(keys), labelsMap, data: formattedData, indexBy: 'category' };
};

// FIXME: Validate widgetData contains only one metric
type PieHistogramDataReturnType = {
  data: NivoBarData[];
};
export const buildPieHistogramDataFromMetrics = (
  widgetData: MetricsData
): PieHistogramDataReturnType => {
  const formattedData: NivoBarData[] = [];

  widgetData.results.forEach((metric) => {
    metric.x_axis.forEach((x, index) => {
      formattedData[index] = {
        ...formattedData[index],
        id: index,
        label: x.label,
        value: metric.y_axis[index],
        labelValue: x.value,
      };
    });
  });

  return { data: formattedData };
};

// FIXME: Validate widgetData contains the same type for all metrics
type SparkLineDateHistogramDataReturnType = {
  data: Serie[];
};
export const buildSparkLineDateHistogramDataFromMetrics = (
  widgetData: MetricsData,
  queryData: QueryMetricObject
): SparkLineDateHistogramDataReturnType => {
  let formattedData: NivoBarData[] = [];
  const formattedResults: Serie[] = [];

  widgetData.results.forEach((metric, idx) => {
    metric.x_axis.forEach((x, index) => {
      formattedData[index] = {
        x: getLabelFormatFromPeriod({
          timestamp:
            typeof x === 'string' || typeof x === 'number' ? (x as number) : (x.value as number),
          period: queryData.queries[idx].period as number,
          unitOfTime: queryData.queries[idx].unit_of_time,
        }),
        y: metric.y_axis[index],
      };
    });
    formattedResults.push({ data: formattedData, label: metric.label, id: metric.id });
    formattedData = [];
  });

  return { data: formattedResults };
};

export const buildProgressBarMetrics = (
  data: {
    results: Array<Metric & { ratio: number; has_event: number }>;
  },
  queryData: QueryMetricObject
): { data: SimpleDataMetric } | { error: string } => {
  try {
    const formattedData: SimpleDataMetric = [];
    data.results.forEach((result, idx) => {
      const { icon } = queryData.queries[idx];

      formattedData.push({
        metric: result.ratio,
        count: `${result.has_event}`,
        label: queryData.queries[idx].label,
        ...(icon != null && { icon: iconsMap[icon] }),
      });
    });
    return { data: formattedData };
  } catch (e) {
    return { error: 'You cannot use this widget with multiple metrics' };
  }
};

const camelizeNivoProps = (obj: NivoArgsProps): NivoBarProps =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key.toString());

    // @ts-expect-error Add types to this
    acc[camelKey] = isObject(value) ? camelizeNivoProps(value) : value;
  });

export const validateNivoBarProps = (
  nivoProps?: NivoArgsProps
): { props: NivoBarProps; error: null } | { props: null; error: string } => {
  if (nivoProps == null) {
    return { props: {}, error: null };
  }

  const nivoPropsCamelCase = camelizeNivoProps(nivoProps);

  // Get props in array
  const properties = Object.keys(nivoPropsCamelCase);
  // Assign default props to final props
  const finalProps = {};

  // Loop through the props
  for (let i = 0; i < properties.length; i += 1) {
    const prop = properties[i];

    // Check if the prop is correct
    if (!Object.prototype.hasOwnProperty.call(NivoBarUserPropsDefault, prop)) {
      return { props: null, error: `Property ${prop} is invalid.` };
    }
    // Check if the prop's type is correct

    if (
      typeof nivoPropsCamelCase[prop as keyof NivoBarUserProps] !==
      typeof NivoBarUserPropsDefault[prop as keyof NivoBarUserProps]
    ) {
      return { props: null, error: `Property ${prop} has invalid value.` };
    }

    // if prop is axis
    if (prop.includes('axis')) {
      const axis: AxisUserProps = {
        ...(nivoPropsCamelCase[prop as keyof NivoBarUserProps] as AxisUserProps),
      };
      // Assign default props to final props
      // @ts-ignore
      finalProps[prop as keyof NivoBarProps] = { ...NivoBarPropsDefault[prop] };

      if (axis.enable) {
        // Get props in array
        const axisProps = Object.keys(axis);

        // Loop through the axis props
        for (let j = 0; j < axisProps.length; j += 1) {
          const axisProp = axisProps[j];

          // Check if the prop is correct
          if (!Object.prototype.hasOwnProperty.call(axisUserPropsDefault, axisProp)) {
            return { props: null, error: `Property ${axisProp} of ${prop} is invalid.` };
          }

          // Check if the prop's type is correct
          if (
            typeof axis[axisProp as keyof AxisUserProps] !==
            typeof axisUserPropsDefault[axisProp as keyof AxisUserProps]
          ) {
            return { props: null, error: `Property ${axisProp} of ${prop} has invalid value.` };
          }

          // parse format type to format function
          if (axisProp === 'formatType') {
            // @ts-ignore
            finalProps[prop as keyof NivoBarProps].format = formatFunctionDefaults[axis[axisProp]];
          } else if (axisProp !== 'enable') {
            // @ts-ignore
            finalProps[prop as keyof NivoBarProps][axisProp] =
              // @ts-ignore
              nivoPropsCamelCase[prop as keyof NivoBarUserProps][axisProp];
          }
        }
      } else {
        // if axis is not enable return null
        // @ts-ignore
        finalProps[prop as keyof NivoBarProps] = null;
      }
    } else {
      // @ts-ignore
      finalProps[prop as keyof NivoBarProps] = nivoPropsCamelCase[prop as keyof NivoBarUserProps];
    }
  }

  return { props: finalProps, error: null };
};

type SimpleDataMetric = {
  id?: string;
  count: string;
  perChange?: string;
  label: string;
  // meaningful value derived from count
  metric?: number;
  icon?: JSX.Element;
  nonZeroPeriodsCount?: number;
  originalValue?: number;
  timestamp?: string;
}[];

export const buildSimpleDataFromMetrics = (
  data: MetricsData,
  queryData: QueryMetricObject,
  useNewIcons = false
): { data: SimpleDataMetric } | { error: string } => {
  try {
    const formattedData: SimpleDataMetric = [];
    data.results.forEach((result, idx) => {
      if (result.x_axis.length > 1 || result.y_axis.length > 1) {
        throw new Error();
      }

      const icon = queryData.queries[idx]?.icon;

      formattedData.push({
        originalValue: result.y_axis[0],
        count: kFormatter(result.y_axis[0]),
        label: queryData.queries[idx]?.label,
        ...(icon != null && { icon: useNewIcons ? newIconsMap[icon] : iconsMap[icon] }),
      });
    });
    return { data: formattedData };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return { error: 'You cannot use this widget with multiple metrics' };
  }
};

export const buildPeriodTrendAndSimpleDataFromMetrics = (
  data: MetricsData,
  queryData: QueryMetricObject,
  useNewIcons = false
): { data: SimpleDataMetric } | { error: string } => {
  try {
    const formattedData: SimpleDataMetric = [];
    data.results.forEach((result, idx) => {
      const icon = queryData.queries[idx]?.icon;
      const count = result.y_axis[result.y_axis.length - 1];

      const prevPeriodAvg =
        result.y_axis.slice(0, -1).reduce((avg, period) => avg + period, 0) /
        (result.y_axis.length - 1);

      const perChange = Math.round(((count - prevPeriodAvg) / (prevPeriodAvg || count)) * 100) || 0;

      formattedData.push({
        count: kFormatter(count),
        perChange: `${Math.abs(perChange)}% ${perChange >= 0 ? 'above' : 'lower than'} average`,
        label: queryData.queries[idx]?.label,
        // @ts-ignore
        timestamp: result.x_axis[result.x_axis.length - 1]?.value,
        ...(icon != null && { icon: useNewIcons ? newIconsMap[icon] : iconsMap[icon] }),
      });
    });
    return { data: formattedData };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return { error: 'You cannot use this widget with multiple metrics' };
  }
};

export const buildPeriodTrendDataFromMetrics = (
  data: MetricsDataV2,
  useNewIcon = false
  // queryData: QueryMetricObject
): { data: SimpleDataMetric } | { error: string } => {
  try {
    const formattedData: SimpleDataMetric = [];
    data.results.forEach(({ y_axis, x_axis }) => {
      // sort event that have more recent activity
      const orderedYAxis = [...y_axis];
      orderedYAxis.sort(({ values }, { values: valuesB }) => {
        const diff1 = Math.abs(values[values.length - 1] - values[values.length - 2]);
        const diff2 = Math.abs(valuesB[valuesB.length - 1] - valuesB[valuesB.length - 2]);
        return diff2 - diff1;
      });
      // get top 3 priorities from most active y_axis values
      orderedYAxis.slice(0, 3).forEach(({ id, label, values }) => {
        const count = values[values.length - 1];

        const prevMonthsEvaluation = values.slice(0, -1).reduce((acc, curr): number => {
          if (curr > 0 || acc > 0) {
            // eslint-disable-next-line no-param-reassign
            acc += 1;
          }
          return acc;
        }, 0);

        const prevPeriodAvg =
          values.slice(0, -1).reduce((avg, period) => avg + period, 0) / (values.length - 1);

        const perChange = Math.round(((count - prevPeriodAvg) / (prevPeriodAvg || count)) * 100);

        formattedData.push({
          id,
          count: kFormatter(count),
          icon: TREND_ICON(perChange, useNewIcon),
          label,
          nonZeroPeriodsCount: prevMonthsEvaluation,
          timestamp: x_axis[x_axis.length - 1].value,
        });
      });
    });
    // sort alphabetically by label
    return { data: formattedData.sort((a, b) => a.label.localeCompare(b.label)) };
  } catch (e) {
    return { error: 'You cannot use this widget with multiple metrics' };
  }
};

export const getFilterDropdownOptions = (
  filterOptions: { [key: string]: string }[] | null,
  allLabel: string
): {
  value: string;
  label: string;
}[][] => {
  if (filterOptions) {
    return filterOptions.map((option) => {
      const optionEntries = Object.entries(option).map(([key, label]) => ({
        value: key,
        label: capitalize(label),
      }));
      return [...optionEntries, { label: capitalize(allLabel), value: 'all_data' }];
    });
  }
  return [
    [
      ...Object.entries(DEFAULT_FILTER_OPTIONS).map(([key, label]) => ({
        value: key,
        label: capitalize(label),
      })),
      { label: capitalize(allLabel), value: 'all_data' },
    ],
  ];
};

// So colors are in the right order
export const getOrderedColors = (colors: string[], count: number): string[] => {
  const orderedColors = [];
  for (let i = 0; i < count; i += 1) {
    orderedColors.push(colors[i % colors.length]);
  }

  return orderedColors.reverse();
};

// Patterns stuff

/* export const patternMatcherGenerator = (
  keys: string[]
): { match: (d: BarDatum) => boolean; id: string }[] =>
  patterns.map((p) => ({
    match: (d): boolean => {
      // @ts-expect-error BarDatum won't work as type
      const { id } = d.data;
      if (allowedIndexPattern[p as keyof typeof allowedIndexPattern].includes(keys.indexOf(id))) {
        return true;
      }
      return false;
    },
    id: p,
  })); */

export const buildStatisticDataFromSingleMetric = (
  widgetData: MetricsData,
  queryData: QueryMetricObject
): { data: number; label: string } | { error: string } => {
  if (widgetData.results.length > 1) {
    return { error: 'You cannot use this widget with multiple metrics' };
  }

  return { data: widgetData.results[0].y_axis[0], label: queryData.queries[0].label };
};

export const reduceFilters = (current: string, filter: DashboardFilter): string => {
  if (filter.default_value !== undefined) {
    const andParam =
      filter.dimension === 'time_range' || filter.dimension === 'unit_of_time'
        ? `${'&'}${filter.dimension}=${filter.default_value}`
        : `${'&'}metrics__${filter.dimension}=${filter.default_value}`;

    return current.concat(andParam);
  }
  return current;
};
