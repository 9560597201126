import React, { useState } from 'react';

import { setURLParams } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import SearchInput from 'components/SearchInput';
import SelectRedesign from 'components/Select/SelectRedesign';
import { kindOptions } from 'constants/entities';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { useDispatch, useSelector } from 'react-redux';
import { getPrompts, getPromptsLoading } from 'selectors/entities';
import { getNavParamsByResource } from 'selectors/nav';
import EditPromptModal from './EditPromptModal';

const PromptHeader: React.FC = () => {
  const dispatch = useDispatch();

  const kind = useSelector(getNavParamsByResource(resourceQueryParamName.prompts)).kind as string;
  const loading = useSelector(getPromptsLoading);
  const prompts = useSelector(getPrompts);

  const [isCreatingPrompt, setIsCreatingPrompt] = useState(false);

  const toggleCreateModal = (): void => setIsCreatingPrompt(!isCreatingPrompt);

  const handleCreateNew = (): void => {
    setIsCreatingPrompt(true);
  };

  const handleSelectKind = (value: string): void => {
    dispatch(setURLParams({ prompts__kind: value }));
  };

  return (
    <>
      <div className="flex flex-col gap-6 justify-between px-6 w-full">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <h2 className="text-heading-2">Prompts</h2>
            {loading && <LoadingIndicator className="ml-2" size="5" />}
          </div>
          <button
            onClick={handleCreateNew}
            type="button"
            data-testid="create-report-button"
            className="button button--primary h-8 w-37 justify-center"
          >
            New Prompt
          </button>
        </div>
        <div className="flex flex-row justify-between gap-2 h-8">
          <div className="flex gap-2">
            <SearchInput
              className="max-w-60"
              resource={resourceQueryParamName.prompts}
              field="name"
              placeholder="Search prompts"
            />
            <div className="flex-shrink-0 w-32">
              <SelectRedesign
                onChange={handleSelectKind}
                options={kindOptions}
                value={kind}
                placeholder="Type"
                dataTestid="type-select"
              />
            </div>
          </div>
          <div className="self-end">
            {loading ? (
              <LoadingIndicator className="ml-2" size="5" />
            ) : (
              <div className="">
                {prompts.length} of {prompts.length} Prompts
              </div>
            )}
          </div>
        </div>
      </div>

      {isCreatingPrompt && (
        <EditPromptModal
          isCreating
          isModalOpen={isCreatingPrompt}
          toggleModalOpen={toggleCreateModal}
        />
      )}
    </>
  );
};

export default PromptHeader;
