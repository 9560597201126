import React from 'react';

import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import PromptHeader from './PromptHeader';
import PromptTable from './PromptTable';

const PrompList: React.FC = () => {
  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <>
      <div
        className="min-w-screen bg-white"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        <Navbar />
        <div
          className="flex flex-row w-full"
          style={{
            height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
          }}
        >
          <SettingSidebar />
          <div className="pt-4 pb-10 w-full flex flex-col gap-2 overflow-hidden">
            <PromptHeader />
            <main className="flex w-full overflow-hidden">
              <PromptTable />
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrompList;
