/* eslint-disable max-lines */
import { UUID } from '@litlingo/client';
import { deletePrompt } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import LoadingIndicator from 'components/LoadingIndicator';
import AlertsContext from 'contexts/AlertsContext';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDeleteTagGroupLoading } from 'selectors/tagGroup';
import { useSelector } from 'store';

type ComponentProps = {
  selectedPrompts: UUID[];
  setSelectedPrompts: React.Dispatch<React.SetStateAction<string[]>>;
  toggleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PromptsListActionRow: React.FC<ComponentProps> = ({
  selectedPrompts,
  setSelectedPrompts,
  toggleModalOpen,
}) => {
  const dispatch = useDispatch();
  const [isDeletingTagGroup, setIsDeletingTagGroup] = useState(false);
  const deleteLoading = useSelector(getDeleteTagGroupLoading);

  const { showSuccessAlert } = useContext(AlertsContext);

  const toggleConfirmModal = (): void => setIsDeletingTagGroup(!isDeletingTagGroup);

  const handleDeleteClick = (): void => {
    if (selectedPrompts.length > 0) {
      selectedPrompts.forEach((p) => {
        dispatch(deletePrompt({ uuid: p }));
      });
      toggleConfirmModal();
      setSelectedPrompts([]);
    }
  };

  const handleCopyClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();
    await navigator.clipboard.writeText(selectedPrompts[0]);
    showSuccessAlert('Prompt UUID copied to your clipboard');
  };

  return (
    <>
      <tr className="bg-litlingo-gray-2">
        <th
          colSpan={6}
          className="table-wrapper__new-td table-wrapper__bulk-area bg-litlingo-gray-2"
        >
          <div className="table-wrapper__new-cell-content flex flex-row justify-between items-start gap-3 py-2 m-0 h-auto overflow-visible">
            <div className="w-full pl-6 pr-4 flex flex-row justify-between items-start gap-2">
              <div className="flex flex-row gap-2">
                <button
                  type="button"
                  disabled={selectedPrompts.length !== 1}
                  onClick={(e): Promise<void> => handleCopyClick(e)}
                  className="button button--secondary font-bold h-8 w-24 flex justify-center"
                >
                  Copy ID
                </button>

                <button
                  type="button"
                  disabled={selectedPrompts.length !== 1}
                  onClick={(): void => toggleModalOpen(true)}
                  className="button button--secondary font-bold h-8 w-24 flex justify-center"
                >
                  Edit
                </button>
              </div>
              <button
                type="button"
                disabled={selectedPrompts.length === 0}
                onClick={(): void => toggleConfirmModal()}
                className="button button--secondary font-bold h-8 w-24 flex justify-center"
              >
                {deleteLoading ? <LoadingIndicator size="5" /> : 'Delete'}
              </button>
            </div>
          </div>
        </th>
      </tr>
      {isDeletingTagGroup && (
        <ConfirmModal
          data-testid="delete-modal"
          text="Are you sure you want to delete this tag group?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeleteClick}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default PromptsListActionRow;
