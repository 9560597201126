/* eslint-disable no-unused-expressions */
/* eslint-disable max-lines */

import {
  PostTestSentencePayloadV2,
  clearTestSentence,
  postTestSentenceV2,
  replaceURLParams,
} from 'actions';
import { setCurrentTestIdentifierRevisionId } from 'actions/identifier';
import { setShowHighlights } from 'actions/ruleGroup';
import LoadingIndicator from 'components/LoadingIndicator';
import SelectRedesign from 'components/Select/SelectRedesign';
import SentencesViolatedGlobalSidebarSection from 'components/SentencesViolatedSection/SentencesViolatedGlobalSidebarSection';
import keyMap from 'constants/configHotKeys';
import { EXPAND_ICON } from 'constants/dashboardIcons';
import React, { useRef, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAnnotatedTestSentenceResultV2,
  getAnnotationMatchedLanguageV2,
  getCommunicationTest,
  getShowV2Graph,
} from 'selectors/communications';
import { getCurrentTestIdentifierRevisionId, getIdentifierCustomers } from 'selectors/identifier';
import { getNavParams } from 'selectors/nav';
import { getPreviewRuleGroupLoading } from 'selectors/ruleGroup';
import logEvent from 'utils/analytics';
import { v4 } from 'uuid';

type ComponentProps = {
  testPhrase?: string;
  identifierId?: string;
};

const TestSentenceVisualizerSidebarIdentifiers: React.FC<ComponentProps> = ({
  testPhrase,
  identifierId,
}) => {
  const { useEffect } = React;
  const dispatch = useDispatch();
  const testSentenceLoading = useSelector(getPreviewRuleGroupLoading);

  const resultLinesV2 = useSelector(getAnnotatedTestSentenceResultV2);
  const annotationsMatchedV2 = useSelector(getAnnotationMatchedLanguageV2);

  const communicationTest = useSelector(getCommunicationTest);

  const bodyParam = useSelector(getNavParams).body || '';

  const currentTestIdentifierId = useSelector(getCurrentTestIdentifierRevisionId);
  const showV2 = useSelector(getShowV2Graph);
  const customers = useSelector(getIdentifierCustomers);

  const [body, setBody] = useState<string>(bodyParam as string);
  const [debouncedBody, setDebouncedBody] = useState('');
  const [firstTime, setFirstTime] = useState(true);
  const [selectedCustomerTest, setSelectedCustomerTest] = useState<string>('');

  const [channelId] = useState(v4());

  const RunButtonRef = useRef<HTMLButtonElement>(null);
  const SaveButtonRef = useRef<HTMLButtonElement>(null);
  const TextAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const channel = new BroadcastChannel(`test-visualizer-channel-${channelId}`);
    if (communicationTest) {
      channel.postMessage({ communication: communicationTest });
    }
  }, [communicationTest, channelId]);

  useEffect(() => {
    const channel = new BroadcastChannel(`test-visualizer-channel-${channelId}`);

    const handleEvent = (event: MessageEvent<{ ready: boolean }>): void => {
      if (event.data.ready) {
        if (communicationTest) {
          channel.postMessage({ communication: communicationTest });
        }
      }
    };

    channel.addEventListener('message', handleEvent);

    return () => {
      channel.removeEventListener('message', handleEvent);
    };
  }, [communicationTest, channelId]);

  // works as a componentWillUnmount to avoid having data when returning to this view
  // @ts-ignore
  useEffect(() => {
    if (!currentTestIdentifierId) {
      dispatch(clearTestSentence());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTestIdentifierId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedBody(body), 500);
    return (): void => {
      clearTimeout(timeoutId);
    };
  }, [body]);

  useEffect(() => {
    if (bodyParam !== debouncedBody) {
      dispatch(replaceURLParams({ body: debouncedBody }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBody, dispatch]);

  useEffect(() => {
    if (testPhrase) {
      setBody(testPhrase || '');
    }
  }, [testPhrase]);

  const submitTest = (useV2 = false): void => {
    const payload: PostTestSentencePayloadV2 = {
      body,
      platform: 'all',
      force_v2_graphs: useV2,
      ...(selectedCustomerTest === 'all' || !selectedCustomerTest
        ? {}
        : { customer_uuid: selectedCustomerTest }),
    };
    payload.identifiers = [{ uuid: identifierId || '' }];
    dispatch(postTestSentenceV2(payload));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    logEvent('tests-run-test');

    submitTest(showV2);
  };

  useEffect(() => {
    if (!bodyParam) return;
    setBody(bodyParam as string);
    if (firstTime) {
      submitTest(true);
      setFirstTime(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyParam, firstTime]);

  const handlers = {
    RUN_TEST_PHRASE: (): void => {
      if (document.activeElement === TextAreaRef?.current) {
        RunButtonRef?.current?.click();
      }
    },
    RUN_TEST_PHRASE_ALT: (): void => {
      if (document.activeElement === TextAreaRef?.current) {
        RunButtonRef?.current?.click();
      }
    },
    SAVE_TO_SUITE: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        if (document.activeElement === TextAreaRef?.current) {
          SaveButtonRef?.current?.click();
        }
      }
    },
  };

  const customerOptions = [
    ...customers.map((cus) => ({
      label: cus.name || '',
      value: cus.uuid || '',
    })),
    {
      label: 'All',
      value: 'all',
    },
  ];

  return (
    <div className="p-4">
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div className="flex flex-col gap-4 pb-5">
        <div className="flex flex-row gap-2 justify-end items-center">
          <span>on</span>
          <div className="w-36">
            <SelectRedesign
              placeholder="Customers"
              onChange={(option: string): void => {
                setSelectedCustomerTest(option);
              }}
              options={customerOptions}
              value={selectedCustomerTest}
              dataTestid="user-customers"
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <form
            onSubmit={handleSubmit}
            data-testid="test-viz-phrase-form"
            className="flex flex-col gap-4"
          >
            <div className="flex flex-col gap-1">
              <textarea
                ref={TextAreaRef}
                id="body"
                data-testid="test-viz-phrase-input"
                rows={12}
                className="form-textarea text-body w-full transition duration-150 ease-in-out resize-none"
                onChange={(event): void => {
                  setBody(event.target.value);
                }}
                value={body}
              />

              <p className="text-small">
                <strong>Ctrl + Enter </strong>
                to test the phrase.
              </p>
            </div>

            <button
              ref={RunButtonRef}
              type="submit"
              data-testid="run-test-button"
              className={`h-8 w-37 text-body button button--primary flex justify-center self-end ${
                body === '' ? 'bg-opacity-25' : ''
              }`}
              disabled={body === ''}
              onClick={(): void => {
                dispatch(setCurrentTestIdentifierRevisionId(identifierId ?? ''));
                dispatch(setShowHighlights(true));
              }}
            >
              <span className="text-white font-bold">
                {testSentenceLoading ? <LoadingIndicator size="5" /> : 'Run Test'}
              </span>
            </button>
          </form>
        </div>
        <div className="border-b border-litlingo-gray-4" />

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <span className="text-heading-3">Results</span>

            {resultLinesV2 ? (
              <div className="flex flex-col gap-4">
                {resultLinesV2 && (
                  <SentencesViolatedGlobalSidebarSection
                    resultLines={resultLinesV2}
                    annotationsMatched={annotationsMatchedV2}
                  />
                )}
              </div>
            ) : (
              <span className="text-body italic text-litlingo-gray-4">
                Run your test to see the results here.
              </span>
            )}
          </div>
          {resultLinesV2 && (
            <a
              className="flex flex-row items-center gap-2 self-center text-body"
              target="_blank"
              rel="noopener noreferrer"
              href={`${window.location.origin}/global/test-viz-result?channel_id=${channelId}`}
            >
              <span>{EXPAND_ICON}</span>
              <span>Language Visualizer Pop-out</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestSentenceVisualizerSidebarIdentifiers;
