import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPrompt, getSinglePromptLoading } from 'selectors/entities';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import PromptContent from './PromptContent';
import PromptHeader from './PromptHeader';

const Prompt: React.FC = (): JSX.Element => {
  const loading = useSelector(getSinglePromptLoading);
  const prompt = useSelector(getPrompt);

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="flex flex-col bg-white min-w-screen"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        {loading || !prompt ? (
          <div className="w-full h-full flex justify-center items-center">
            <LoadingIndicator className="-mt-60" size="20" />
          </div>
        ) : (
          <div className="py-6 pl-6 flex flex-col gap-6 w-full">
            <PromptHeader />

            <PromptContent />
          </div>
        )}
      </div>
    </div>
  );
};

export default Prompt;
