/* eslint-disable max-lines */

import {
  BarDatum,
  BarExtendedDatum,
  BarMouseEventHandler,
  BarTooltipDatum,
  ResponsiveBar,
} from '@nivo/bar';
import { Datum } from '@nivo/line';
import { clearTree, requestTreeFiltersToogle } from 'actions';
import { allowedReadOnlyDimensions, colors, metricsLinksMap } from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DefaultWidgetComponentProps, MetricsDataV2 } from 'types';
import logEvent from 'utils/analytics';
import {
  DateHistogramDataReturnType,
  buildStackedDateHistogramDataFromMetricsV2,
  getOrderedColors,
  getTotalCountFromDateHistogramData,
} from 'utils/dashboard';
import { getValuesFromDimensions, setNewValue, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { useTimeRange } from 'utils/timeRanges';
import { useHistory } from 'utils/urls';

type Coords = {
  left?: number;
  top?: number;
};

const ToolTip = ({
  datum,
  widgetData,
  processedData,
  isPercent,
  coords,
}: {
  datum: BarTooltipDatum;
  widgetData: MetricsDataV2;
  processedData: DateHistogramDataReturnType;
  isPercent: boolean;
  coords: Coords | null;
}): JSX.Element => {
  const originalValue = useMemo(
    () =>
      widgetData?.results[0]?.y_axis?.find((bucket: { id: string }) => bucket.id === datum.id)
        ?.values[datum.index as number] || 0,
    [widgetData, datum.id, datum.index]
  );

  const focusedValue = useMemo(
    () => isPercent && ` - ${((datum.value as number) * 100).toFixed(2)}%`,
    [datum.value, isPercent]
  );

  const portal = document.getElementById('tooltip-portal');

  const ttip = (
    <div
      data-testid="tooltip-text"
      style={{
        left: (coords?.left || 0) + 10,
        top: coords?.top,
        zIndex: 1000,
      }}
      className=" bg-litlingo-off-white p-1 rounded flex items-center shadow-md fixed"
    >
      <div
        style={{
          background: datum.color,
        }}
        className="h-3 w-3 mr-2"
      />
      <span className="text">
        <b>{processedData.labelsMap[datum.id]}</b> - {datum.indexValue}:{' '}
        <b>{isPercent ? originalValue : datum.value}</b>
        <b>{focusedValue}</b>
      </span>
    </div>
  );

  if (portal) {
    return ReactDOM.createPortal(ttip, portal);
  }
  return <></>;
};

/* type SymbolProps = {
  id: string | number;
  x: number;
  y: number;
  size: number;
  fill: string;
  // opacity?: number;
  borderWidth?: number;
  borderColor?: string;
  keys: string[];
};

const CustomSymbolShape: React.FC<SymbolProps> = ({
  id,
  x,
  y,
  size,
  fill,
  // opacity,
  borderWidth,
  borderColor,
  keys,
}): JSX.Element => {
  let pattern: patternType = null;
  const i = Math.floor(keys.indexOf(id as string) / colors.length);
  pattern = (patterns[i - 1] as patternType) || null;

  return (
    <rect
      x={x}
      y={y}
      fill={pattern ? `url(#${pattern}.bg.${fill})` : fill}
      strokeWidth={borderWidth}
      stroke={borderColor}
      width={size}
      height={size}
      style={{ pointerEvents: 'none' }}
    />
  );
}; */

// FIXME: Change DefaultWidgetComponentProps to use new metrics type
// once the migration to the new structure is done
export const StackedDateHistogramWidget: React.FC<
  DefaultWidgetComponentProps & { widgetData: MetricsDataV2 } & RouteComponentProps<{
      [paramName: string]: string | undefined;
    }>
> = (props) => {
  const { widgetId, widgetData, queryData, nivoProps, isPercent = false, isExpandedView } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));

  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));
  const range = useTimeRange();
  const [coords, setCoords] = useState<Coords | null>({});
  const [datumLegend, setDatumLegend] = useState<Datum | null>();

  const processedData = useMemo(
    () =>
      widgetData &&
      queryData &&
      buildStackedDateHistogramDataFromMetricsV2(widgetData, queryData, isPercent),
    [widgetData, queryData, isPercent]
  );

  if (widgetData == null || queryData == null || !processedData) {
    return <WidgetLoading />;
  }

  const totalCount = getTotalCountFromDateHistogramData(processedData);
  if (totalCount === 0) {
    return <WidgetEmptyState />;
  }

  const handleClick = (datum: BarExtendedDatum): void => {
    // this widget only ever has a single metric config
    // so we pluck the first query config
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);

    const query = queryData.queries[0];
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const series = widgetData.results[0];

    if (series == null) {
      return;
    }

    const start = Number(
      Date.parse(
        series.x_axis[datum.index].value.slice(-1) === 'Z' ||
          series.x_axis[datum.index].value[series.x_axis[datum.index].value.length - 6] === '-' ||
          series.x_axis[datum.index].value[series.x_axis[datum.index].value.length - 6] === '+'
          ? series.x_axis[datum.index].value
          : series.x_axis[datum.index].value.concat('Z')
      )
    );
    const end =
      range.created_before && datum.index + 1 === series.x_axis.length
        ? Number(Date.parse(range.created_before))
        : Number(
            Date.parse(
              series.x_axis[datum.index + 1].value.slice(-1) === 'Z' ||
                series.x_axis[datum.index + 1].value[
                  series.x_axis[datum.index + 1].value.length - 6
                ] === '-' ||
                series.x_axis[datum.index + 1].value[
                  series.x_axis[datum.index + 1].value.length - 6
                ] === '+'
                ? series.x_axis[datum.index + 1].value
                : series.x_axis[datum.index + 1].value.concat('Z')
            )
          );
    const dimensions: Record<string, string | string[]> = {};

    if (info.dimensions != null) {
      Object.entries(info.dimensions).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];
        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue; // topValue
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = widgetFilters[dimension];
          value = widgetValue; // widgetValue
        }

        if (query.group_by === dimension || query.group_by?.includes(dimension)) {
          value = datum.id as string;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
    }

    let groupBy: string;
    let isReviewedReviewValue = false;
    if (Array.isArray(query.group_by)) {
      // eslint-disable-next-line prefer-destructuring
      groupBy = query.group_by[0];
      isReviewedReviewValue = query.group_by.some((item) => item === 'is_reviewed_review_value');
    } else {
      groupBy = query.group_by ?? '';
      isReviewedReviewValue = groupBy === 'is_reviewed_review_value';
    }
    const datumId = datum.id as string;
    const datumIdData: Record<string, string | string[]> = {};

    if (datumId === 'reviewed') {
      datumIdData[`${info.resource}__review_values`] = ['in_policy', 'out_of_policy'];
    } else if (isReviewedReviewValue && datumId === 'pending') {
      datumIdData[`${info.resource}__review_values`] = ['pending', 'skipped'];
    }

    const startValue = moment.utc(start).format(FULL_DATE_FORMAT);
    const endValue = moment.utc(end).format(FULL_DATE_FORMAT);

    const tree = getValuesFromDimensions({ ...dimensions, ...datumIdData });
    const newTree = setNewValue(tree, 'date_range', `${startValue}<>${endValue}`, '', true);

    dispatch(
      requestTreeFiltersToogle({
        value: true,
      })
    );
    dispatch(clearTree());
    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__filters_search`]: transformToString(newTree),
        [`${info.resource}__has_events`]: ['true'],
        ...dimensions,
        ...datumIdData,
      },
    });
  };

  const handleMouseEnter = (
    _datum: BarExtendedDatum,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-hover-${id}-${type}`);

    setCoords({
      left: event.pageX,
      top: event.pageY,
    });

    const query = queryData.queries[0];
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];

    if (info != null) {
      event.currentTarget.style.cursor = 'pointer';
    }
  };

  const renderTooltip = (content: Datum): React.ReactPortal | null => {
    const { id } = content;
    const portal = document.getElementById('tooltip-portal');

    const ttip = (
      <div
        data-testid="tooltip-text"
        style={{
          left: coords?.left,
          top: coords?.top,
        }}
        className=" bg-litlingo-off-white p-1 rounded flex items-center shadow-md fixed "
      >
        <span className="text">
          <b>{processedData.labelsMap[id] ?? id}</b>
        </span>
      </div>
    );
    if (portal) {
      return ReactDOM.createPortal(ttip, portal);
    }
    return null;
  };

  const handleMouseEnterLengend = (
    datum: Datum,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ): void => {
    const { top, left, width } = event.currentTarget.getBoundingClientRect();
    setCoords({
      left: left + width,
      top,
    });
    setDatumLegend(datum);
  };

  const handleMouseLeaveLengend = (): void => {
    setCoords(null);
    setDatumLegend(null);
  };

  const { colors: nivoColors, ...nivoArgs } = nivoProps || {};
  let widgetColors = colors;
  if (nivoColors) {
    widgetColors = nivoColors;
  }

  const renderChart = (): JSX.Element => (
    <>
      <div className={`h-full ${isExpandedView ? '' : ''}`}>
        <ResponsiveBar
          colors={getOrderedColors(widgetColors, processedData.keys.length)}
          // defs={patternsDefs}
          // fill={patternMatcherGenerator(processedData.keys)}
          padding={0.5}
          legends={[
            {
              dataFrom: 'keys',
              anchor: isExpandedView ? 'top-left' : 'bottom-right',
              direction: 'column',
              itemWidth: 100,
              itemHeight: 20,
              translateX: isExpandedView ? -40 : 130,
              translateY: isExpandedView ? 530 : undefined,
              toggleSerie: true,
              onMouseEnter: handleMouseEnterLengend,
              onMouseLeave: handleMouseLeaveLengend,
              // symbolShape: (p) => CustomSymbolShape({ ...p, keys: processedData.keys })
            },
          ]}
          legendLabel={(datum: BarDatum): string => {
            const labelTemp = processedData.labelsMap[datum.id].substring(0, 25);
            const label =
              processedData.labelsMap[datum.id].length > 27
                ? `${labelTemp}...`
                : processedData.labelsMap[datum.id];
            return isExpandedView ? processedData.labelsMap[datum.id] : label;
          }}
          margin={{
            top: 10,
            right: isExpandedView ? 50 : 220,
            bottom: isExpandedView ? 760 : 50,
            left: isExpandedView ? 50 : 50,
          }}
          axisLeft={{
            format: isPercent
              ? (value): string => {
                  const formatter = new Intl.NumberFormat('en-US', {
                    style: 'percent',
                  });
                  const percentFormat = formatter.format((value as number) / 1);
                  return percentFormat;
                }
              : (value): string => value,
          }}
          data={processedData.data}
          keys={processedData.keys}
          indexBy={processedData.indexBy}
          enableLabel={false}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          theme={{
            tooltip: {
              container: {
                background: 'none',
                boxShadow: 'none',
              },
            },
          }}
          axisBottom={{
            tickRotation: -49,
            legendOffset: 30,
            tickPadding: 5,
          }}
          tooltip={(datum: BarTooltipDatum): JSX.Element => (
            <ToolTip
              datum={datum}
              widgetData={widgetData}
              processedData={processedData}
              isPercent={isPercent}
              coords={coords}
            />
          )}
          animate
          onClick={handleClick as BarMouseEventHandler<SVGElement>}
          onMouseEnter={handleMouseEnter as BarMouseEventHandler<SVGElement>}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...nivoArgs}
        />
      </div>
      <div className="flex">{datumLegend && renderTooltip(datumLegend)}</div>
    </>
  );

  return (
    <div className={`flex flex-col h-full ${!isExpandedView && 'overflow-hidden'}`}>
      {renderChart()}
    </div>
  );
};

export default withWidget({
  title: 'Events',
  subtitle: '',
  allowDownload: true,
  allowExpandedView: true,
  allowTableView: true,
  reRenderWhitGrouping: true,
})(withRouter(StackedDateHistogramWidget) as unknown as React.FC<DefaultWidgetComponentProps>);
// FIXME: Remove this cast when the migration to the new structure is done
