import EditPromptModal from 'components/PromptList/EditPromptModal';
import { COPY_ID_ICON } from 'constants/envelopeIcons';
import AlertsContext from 'contexts/AlertsContext';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPrompt } from 'selectors/entities';

const PromptHeader: React.FC = () => {
  const prompt = useSelector(getPrompt);
  const { showSuccessAlert } = useContext(AlertsContext);

  const [isEditingPrompt, setIsEditingPrompt] = useState(false);

  const toggleEditModal = (): void => setIsEditingPrompt(!isEditingPrompt);

  // @ts-ignore
  const { uuid, name, description } = prompt;

  const handleCopyClick = async (): Promise<void> => {
    await navigator.clipboard.writeText(uuid);
    showSuccessAlert('Prompt UUID copied to your clipboard');
  };

  if (!prompt) return null;

  return (
    <div className="pr-6 w-full flex flex-col gap-2">
      <div className="w-full flex flex-row justify-between h-8 items-center">
        <span className="text-heading-2">{name}</span>
        <div className="flex flex-row gap-2">
          <button type="button" className="focus:outline-none" onClick={handleCopyClick}>
            {COPY_ID_ICON}
          </button>
          <button
            type="button"
            className="button button--secondary font-bold h-8"
            onClick={toggleEditModal}
          >
            Edit
          </button>
          <button type="button" className="button button--secondary font-bold h-8" disabled>
            Make a copy
          </button>
        </div>
      </div>
      {description && <div>{description}</div>}
      {isEditingPrompt && (
        <EditPromptModal
          isModalOpen={isEditingPrompt}
          toggleModalOpen={toggleEditModal}
          prompt={prompt}
        />
      )}
    </div>
  );
};

export default PromptHeader;
