import { deletePrompt } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import { promptKindMap, promptSourceMap } from 'constants/entities';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrompt } from 'selectors/entities';

const PromptContent: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const prompt = useSelector(getPrompt);
  const [isDeletingPrompt, setIsDeletingPrompt] = useState(false);

  // @ts-ignore
  const { uuid, kind, deployment, source, content, schema_json: schema } = prompt;

  const toggleDeleteModal = (): void => setIsDeletingPrompt(!isDeletingPrompt);

  const handleDeletePrompt = (): void => {
    dispatch(deletePrompt({ uuid, redirect: true }));
    toggleDeleteModal();
  };

  return (
    <div className="h-full w-full flex flex-row gap-10 text-body overflow-hidden">
      <div className="flex flex-col items-start gap-4 min-w-50">
        {/* <span className="italic">Currently in use</span> */}
        <div className="flex flex-col gap-1">
          <span className="font-bold">Type:</span>
          <span>{promptKindMap[kind]}</span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-bold">Deployed with:</span>
          <span>{deployment}</span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="font-bold">Source:</span>
          <span>{promptSourceMap[source]}</span>
        </div>
        <button
          type="button"
          className="text-litlingo-alert underline focus:outline-none"
          onClick={toggleDeleteModal}
        >
          Delete prompt
        </button>
      </div>
      <div className="h-full w-full flex flex-col gap-6 overflow-auto">
        <div className="flex flex-col gap-1">
          <span className="font-bold">Prompt:</span>
          <span className="max-w-152 whitespace-pre-line">{content}</span>
        </div>
        {schema && (
          <div className="flex flex-col gap-1">
            <span className="font-bold">Data Shape:</span>
            <pre className="max-w-152 text-body">{JSON.stringify(schema, undefined, 4)}</pre>
          </div>
        )}
      </div>
      {isDeletingPrompt && (
        <ConfirmModal
          data-testid="delete-modal"
          text="Are you sure you want to delete this promt?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeletePrompt}
          toggleShowModal={toggleDeleteModal}
        />
      )}
    </div>
  );
};

export default PromptContent;
