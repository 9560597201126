import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';

import { getPrompts, getPromptsLoading, getPromptsTotalCount } from 'selectors/entities';
import EditPromptModal from './EditPromptModal';
import PromptListRow from './PromptListRow';
import PromptsListActionRow from './PromptsListActionRow';

const PromptTable: React.FC = () => {
  const prompts = useSelector(getPrompts);
  const loading = useSelector(getPromptsLoading);
  const isEmpty = prompts.length === 0;

  const [selectedPrompts, setSelectedPrompts] = useState<string[]>([]);
  const [isEditingPrompt, setIsEditingPrompt] = useState(false);

  const toggleEditModal = (): void => setIsEditingPrompt(!isEditingPrompt);

  return (
    <div className="table-wrapper__new custom-scrollbar overflow-auto">
      <LoadingOverlay
        active={loading}
        spinner={<LoadingIndicator size="10" />}
        fadeSpeed={0}
        styles={{
          content: (base) => ({
            ...base,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          wrapper: (base) => ({
            ...base,
            height: '100%',
          }),
          spinner: () => ({}),
          overlay: (base) => ({
            ...base,
            zIndex: 10,
          }),
        }}
      >
        <table className="table-wrapper__table table-wrapper_table--fixed">
          <thead className="sticky top-0 z-10">
            <tr>
              <th className="table-wrapper__new-th py-0 table-wrapper__th--w-5">
                <div className="w-full pr-2 flex justify-end">
                  <input
                    id="checkbox-tag-groups"
                    data-testid="checkbox"
                    type="checkbox"
                    className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                      selectedPrompts?.length > 0 && selectedPrompts?.length < prompts.length
                        ? 'checkbox-select-all-partial'
                        : ''
                    }`}
                    onClick={(e): void => e.stopPropagation()}
                    onChange={(): void => {
                      if (selectedPrompts.length === prompts.length) {
                        setSelectedPrompts([]);
                      } else {
                        setSelectedPrompts(prompts.map((p) => p.uuid));
                      }
                    }}
                    checked={
                      selectedPrompts?.length === prompts.length && selectedPrompts?.length > 0
                    }
                  />
                </div>
              </th>
              <th className="table-wrapper__new-th">Name</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-12">Type</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-16 leading-3">
                Deployment
              </th>
              <th className="table-wrapper__new-th table-wrapper__th--w-12">Source</th>
              <th className="table-wrapper__new-th table-wrapper__th--w-25">Usage</th>
            </tr>
            {selectedPrompts?.length >= 1 && (
              <PromptsListActionRow
                selectedPrompts={selectedPrompts}
                setSelectedPrompts={setSelectedPrompts}
                toggleModalOpen={toggleEditModal}
              />
            )}
          </thead>
          <tbody className="table-wrapper__tbody">
            {isEmpty ? (
              <EmptyTableState message="There are no prompts to display" colCount="6" />
            ) : (
              prompts?.map((prompt) => (
                <PromptListRow
                  prompt={prompt}
                  key={prompt.uuid}
                  selected={selectedPrompts.includes(prompt.uuid)}
                  setSelectedPrompts={setSelectedPrompts}
                />
              ))
            )}
          </tbody>
        </table>
        <div className="border-t border-gray-200 sticky bottom-0 bg-white">
          <ListFooter
            resourceStateName={resourceQueryParamName.prompts}
            resourceName="prompt"
            getResourceList={getPrompts}
            getResourceTotalCount={getPromptsTotalCount}
            getResourceLoading={getPromptsLoading}
          />
        </div>
      </LoadingOverlay>
      {isEditingPrompt && (
        <EditPromptModal
          isModalOpen={isEditingPrompt}
          toggleModalOpen={toggleEditModal}
          prompt={prompts.find((p) => selectedPrompts.includes(p.uuid))}
        />
      )}
    </div>
  );
};

export default PromptTable;
